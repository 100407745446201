import React, { useEffect, useState } from 'react';

import { DeleteOutline } from '@mui/icons-material';
import {
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { APIEndpoint } from 'common/enum';
import { addressFormInterface } from 'common/interface';
import { universalDeleteRequest } from 'common/requestHandler';
import { getUsers } from 'common/utils';
import AddressUpdateComp from 'components/address/AddressUpdateComp';
import OverlayLoader from 'components/elements/OverlayLoader';
import { popupNotification } from 'notifications/ToastNotification';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { getUserInterface } from 'screens/accessControl/utils';
import { CustomerInterface } from 'screens/invoice/types';
import {
  getCustomersForInvoice,
  saveInvoiceCustomerDetails,
} from 'screens/invoice/utils';

// import { saveInvoiceCustomerDetails } from '../utils';

const data = [
  {
    name: 'shiv',
    id: '123',
  },
  {
    name: 'amit paka',
    id: '1245',
  },
  {
    name: 'kilian',
    id: '890',
  },
];
const InvoiceCustomerManagement: React.FC = () => {
  // Accessing Redux state using useSelector
  const { companyId, employeeId } = useSelector(
    (state: RootState) => state.auth
  );
  const [usersList, setUsersList] = useState<getUserInterface[]>([]);
  const [existingUsersList, setExistingUsersList] = useState(
    [] as CustomerInterface[]
  );
  const [gst, setgst] = useState('');
  const [selectedUser, setSelectedUser] = useState('' as string);
  const [loading, setLoading] = useState(true);
  const getAllUsers = async () => {
    const response = await getUsers(companyId, 'GetAllUsers', employeeId);
    setLoading(false);
    if ('error' in response) {
      popupNotification(response.error, false);
    } else {
      setUsersList(response.data);
    }
  };
  const handleSelectChange = (e: SelectChangeEvent<string | undefined>) => {
    const userId = e.target.value as string;
    setSelectedUser(userId);
  };
  useEffect(() => {
    if (companyId && employeeId) {
      getAllUsers();
    }
  }, []);
  const handleAddress = async (address: addressFormInterface) => {
    setLoading(true);
    const customer = usersList.filter((user) => user.id === selectedUser);
    const customerName = customer[0].name.split(' - ')[0];
    const payload = {
      httpMethod: 'POST',
      actionName: 'CreateCustomerDetails',
      customerId: selectedUser,
      customerName: customerName,
      customerDetails: {
        ...address,
        gst,
        customerName: customerName,
        customerId: selectedUser,
      },
    };
    delete payload.customerDetails.companyName;
    await saveInvoiceCustomerDetails(payload);
    setSelectedUser('');
    setgst('');
    setLoading(false);
  };
  useEffect(() => {
    getCustomersForInvoice(setExistingUsersList);
  }, []);
  const handleDeleteCustomer = async (customer: CustomerInterface) => {
    const response = await universalDeleteRequest(APIEndpoint.secDocsMain, {
      httpMethod: 'DELETE',
      actionName: 'DeleteCustomerDetails',
      customerId: customer.customerId,
    });
    if ('error' in response) {
      popupNotification(response.error, false);
    } else {
      const customers = existingUsersList.filter(
        (user) => user.customerId !== customer.customerId
      );
      setExistingUsersList(customers);
    }
  };
  return (
    <>
      <OverlayLoader loading={loading} text="Please wait" />
      <Grid container spacing={2} mb={2} mt={2}>
        <Grid item md={6} xs={12}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              Select customer
            </InputLabel>
            <Select
              value={selectedUser}
              onChange={handleSelectChange}
              title="d"
              label="Select customer"
              // displayEmpty
            >
              <MenuItem value="">
                <em>Select customer</em>
              </MenuItem>
              {usersList.map((user) => (
                <MenuItem key={user.id} value={user.id}>
                  {user.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={6}>
          <TextField
            name="gst"
            value={gst}
            onChange={(e) => setgst(e.target.value)}
            fullWidth
            label="GST"
            placeholder="GST"
          />
        </Grid>
      </Grid>

      <AddressUpdateComp
        showEditIcon={false}
        handleSubmitForm={handleAddress}
        submitButtonLabel="Save"
      />
      <Typography variant="body2" fontWeight="bold">
        Existing users
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography fontWeight="bold" variant="subtitle2">
                Customer name
              </Typography>{' '}
            </TableCell>
            <TableCell>
              {' '}
              <Typography fontWeight="bold" variant="subtitle2">
                Customer address
              </Typography>{' '}
            </TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {existingUsersList.map((user) => (
            <TableRow>
              <TableCell>{user.name}</TableCell>
              <TableCell>
                {user?.address.split(',').map((addressBits, index, arr) => (
                  <Typography component="span" variant="body2">
                    {addressBits} {index !== arr.length - 1 ? ',' : ''}
                  </Typography>
                )) || ''}
              </TableCell>
              <TableCell>
                <IconButton
                  color="error"
                  onClick={() => handleDeleteCustomer(user)}
                >
                  <DeleteOutline />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
};

export default InvoiceCustomerManagement;
