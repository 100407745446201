import React, { useCallback, useEffect, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  FormHelperText,
  IconButton,
  LinearProgress,
  Typography,
} from '@mui/material';
import {
  maxFileUploadAllowed,
  maxFileUploadReachedMessage,
} from 'config/config';
import { useDropzone } from 'react-dropzone';

interface FileUploadModalProps {
  open: boolean;
  onClose: () => void;
  onFilesSelected: (files: File[]) => void; // Callback to parent to handle file selection
  progress: { [key: string]: number }; // Progress for each file by file name
  onUpload: () => void; // Callback to start file upload
  uploading: boolean; // Indicates if files are currently uploading
  files: File[]; // List of selected files
  allowMultiple?: boolean;
}

const FileUploadModal: React.FC<FileUploadModalProps> = ({
  open,
  onClose,
  onFilesSelected,
  progress,
  onUpload,
  uploading,
  files,
  allowMultiple = true,
}) => {
  const [isFileCountExceeded, setIsFileCountExceeded] = useState(false);
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      onFilesSelected(acceptedFiles); // Send selected files to the parent component
    },
    [onFilesSelected]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: allowMultiple, // Allows multiple file uploads
  });

  const isUploadDisabled = files.length === 0;
  useEffect(() => {
    if (files.length > maxFileUploadAllowed) {
      setIsFileCountExceeded(true);
    } else {
      setIsFileCountExceeded(false);
    }
  }, [files.length]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: '24px',
        }}
      >
        <Box
          {...getRootProps()}
          sx={{
            border: '2px dashed #9e9e9e',
            borderRadius: '8px',
            padding: '24px',
            width: '100%',
            textAlign: 'center',
            cursor: 'pointer',
            backgroundColor: isDragActive ? '#f0f0f0' : 'transparent',
          }}
        >
          <input {...getInputProps()} />
          <IconButton>
            <AddIcon sx={{ fontSize: '3rem', color: '#9e9e9e' }} />
          </IconButton>
          <Typography variant="body1" mt={2}>
            {isDragActive
              ? 'Drop files here...'
              : 'Select or drag and drop files to upload'}
          </Typography>
        </Box>
        {!uploading && allowMultiple && (
          <FormHelperText error>{maxFileUploadReachedMessage}</FormHelperText>
        )}
        {uploading && (
          <FormHelperText variant="filled">Uploading files...</FormHelperText>
        )}

        <Box mt={3} width="100%">
          {files.map((file) => (
            <Box key={file.name} mb={2}>
              <Typography variant="body2">{file.name}</Typography>
              <LinearProgress
                variant="determinate"
                value={progress[file.name] || 0}
                sx={{ height: '8px', borderRadius: '4px' }}
              />
              <Typography variant="caption">
                {(progress[file.name] || 0).toFixed(0)}%
              </Typography>
            </Box>
          ))}
        </Box>

        <Box mt={3} display="flex" gap={2}>
          <Button
            variant="contained"
            color="primary"
            onClick={onUpload}
            disabled={uploading || isUploadDisabled || isFileCountExceeded} // Disable if uploading or no files
          >
            Upload
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            onClick={onClose}
            disabled={uploading} // Disable if uploading
          >
            Cancel
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default FileUploadModal;
