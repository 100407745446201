import { useEffect, useState } from 'react';

import { Box } from '@mui/material';
import { useFileUpload } from 'hooks/useFileUpload';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';

interface StepFourProps {
  setPdfFile: (file: File | null) => void;
}

const StepThree: React.FC<StepFourProps> = ({ setPdfFile }) => {
  const [showModal, setShowModal] = useState(false);
  const { companyId, firstName, lastName } = useSelector(
    (state: RootState) => state.auth
  );
  const [fileUrl, setFileUrl] = useState('');
  const {
    uploadFiles,
    progress,
    isUploading,
    setSelectedFiles,
    files,
    uploadedUrl,
  } = useFileUpload({
    isQR: true,
    fileName: 'thisisnew',
    fileTypesAllowed: ['application/pdf'],
  });
  async function handleUpload() {
    console.log(files);
    await uploadFiles(
      companyId,
      'QR',
      firstName,
      lastName,
      '',
      setShowModal,
      null
    );
  }
  useEffect(() => {
    if (files.length) {
      setFileUrl(URL.createObjectURL(files[0]));
    }
  }, [files]);
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file && file.type === 'application/pdf') {
      setPdfFile(file);
    } else {
      alert('Please upload a valid PDF file.');
    }
  };

  return (
    <>
      <Box display="flex" alignItems="center" gap={10}>
        <h3>Upload a PDF to Start Signing</h3>
        <input
          type="file"
          accept="application/pdf"
          onChange={handleFileChange}
        />
      </Box>
    </>
  );
};

export default StepThree;
// pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@4.8.69/build/pdf.worker.min.mjs`;
