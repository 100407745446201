import React, { useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import FileUploadModal from 'components/fileComponent/FileUpload';
import { useFileUpload } from 'hooks/useFileUpload';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { validateBankAccount } from 'validation/validationScheme';

import { AddInvoiceCompanyBankDetails } from '../../types';

// import { AddInvoiceCompanyBankDetails } from '../types';

interface Props {
  handleAddBank: (data: AddInvoiceCompanyBankDetails) => void;
  bankList: AddInvoiceCompanyBankDetails[];
  setBankList: React.Dispatch<
    React.SetStateAction<AddInvoiceCompanyBankDetails[]>
  >;
}

const BankDetails: React.FC<Props> = ({
  handleAddBank,
  bankList,
  setBankList,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [localBankList, setLocalBankList] = useState(
    [] as AddInvoiceCompanyBankDetails[]
  );
  const [showQRUpload, setShowQRUpload] = useState(true);
  const { companyId, firstName, lastName } = useSelector(
    (state: RootState) => state.auth
  );
  const {
    control,
    handleSubmit,
    reset,
    getValues,
    watch,
    formState: { errors },
  } = useForm<AddInvoiceCompanyBankDetails>({
    resolver: yupResolver(validateBankAccount),
    defaultValues: {
      accountName: '',
      accountNumber: '' as unknown as number,
      bankName: '',
      accountType: '', // Default to an empty string
      codeType: '',
      bankCode: '',
      qrImage: undefined,
      qrImageUrl: '',
    },
    mode: 'onChange',
  });
  const watchedAccountNumber = watch('accountNumber');

  const {
    uploadFiles,
    progress,
    isUploading,
    setSelectedFiles,
    files,
    uploadedUrl,
    isSuccess,
  } = useFileUpload({
    isQR: true,
    fileName: getValues().accountNumber.toString(),
    fileTypesAllowed: ['image/png'],
  });

  const onSubmit = (data: AddInvoiceCompanyBankDetails) => {
    const updatedData = {
      ...data,
      qrImageUrl: uploadedUrl[0]
        ? companyId +
          '/QR/' +
          data.accountNumber +
          '.' +
          files[0].name.split('.').pop()
        : '',
      // qrImage: files.length ? files[0] : "",
    };
    delete updatedData.qrImage;
    handleAddBank(updatedData);
    reset({
      accountName: '',
      accountNumber: '' as unknown as number,
      bankName: '',
      accountType: undefined,
      codeType: '',
      bankCode: '',
      //   qrImage: undefined,
    }); // Reset all fields explicitly
    setShowQRUpload(true);
    setSelectedFiles([]);
  };
  async function handleQRUpload() {
    await uploadFiles(
      companyId,
      'QR',
      firstName,
      lastName,
      '',
      setShowModal,
      null
    );
    isSuccess && setShowQRUpload(false);
    !isSuccess && setSelectedFiles([]);
  }
  const handleDelete = (index: number) => {
    const updatedList = localBankList.filter((_, i) => i !== index); // Remove the bank at the given index
    setLocalBankList(updatedList); // Update the state
    setBankList(updatedList);
  };
  useEffect(() => {
    setLocalBankList(bankList);
  }, [bankList]);

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Add Bank Account
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid container item xs={12} sm={6} spacing={2}>
            <Grid item xs={12} md={6}>
              <Controller
                name="accountName"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Account Name"
                    variant="outlined"
                    fullWidth
                    error={!!errors.accountName}
                    helperText={errors.accountName?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="accountNumber"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Account Number"
                    variant="outlined"
                    fullWidth
                    error={!!errors.accountNumber}
                    helperText={errors.accountNumber?.message}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="bankName"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Bank Name"
                  variant="outlined"
                  fullWidth
                  error={!!errors.bankName}
                  helperText={errors.bankName?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="accountType"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  select
                  label="Account Type"
                  variant="outlined"
                  fullWidth
                  error={!!errors.accountType}
                  helperText={errors.accountType?.message}
                >
                  <MenuItem value="">
                    <em>Select Account Type</em>
                  </MenuItem>
                  <MenuItem value="Saving">Saving</MenuItem>
                  <MenuItem value="Checking">Checking</MenuItem>
                </TextField>
              )}
            />
          </Grid>
          <Grid container item xs={12} sm={6} spacing={2}>
            <Grid item xs={12} md={6}>
              <Controller
                name="codeType"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    select
                    label="Bank Code Type"
                    variant="outlined"
                    fullWidth
                    error={!!errors.codeType}
                    helperText={errors.codeType?.message}
                  >
                    <MenuItem value="">
                      <em>Select Bank Code Type</em>
                    </MenuItem>
                    <MenuItem value="IFSC">IFSC</MenuItem>
                    <MenuItem value="Routing No">Routing No</MenuItem>
                  </TextField>
                )}
              />
            </Grid>

            <Grid item xs={6} md={6}>
              <Controller
                name="bankCode"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Bank Code"
                    variant="outlined"
                    fullWidth
                    error={!!errors.bankCode}
                    helperText={errors.bankCode?.message}
                  />
                )}
              />
            </Grid>
          </Grid>

          {showQRUpload && (
            <Grid item xs={12} sm={6}>
              <Box
                pl={2}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                border="1px solid #e3e3e3"
              >
                <Typography variant="caption">Upload QR image</Typography>
                <Button
                  variant="text"
                  disabled={!watchedAccountNumber}
                  onClick={() => setShowModal(true)}
                >
                  Select file
                </Button>
              </Box>
              <Typography variant="caption" color="error">
                Upload QR image only png allowed
              </Typography>
            </Grid>
          )}
          <Grid item xs={12}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              startIcon={
                isUploading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : null
              }
            >
              Add Bank Account
            </Button>
          </Grid>
        </Grid>
      </form>
      <FileUploadModal
        open={showModal}
        onClose={() => {
          setShowModal(false);
          setSelectedFiles([]);
        }}
        onFilesSelected={setSelectedFiles}
        progress={progress}
        onUpload={handleQRUpload}
        uploading={isUploading}
        files={files}
      />
      <Box mt={4}>
        <Typography variant="h6">Bank Accounts</Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Account Name</TableCell>
                <TableCell>Account Number</TableCell>
                <TableCell>Bank Name</TableCell>
                <TableCell>Account Type</TableCell>
                <TableCell>Bank Code Type</TableCell>
                <TableCell>Bank Code</TableCell>
                <TableCell>QR Image</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {localBankList.map((bank, index) => (
                <TableRow key={index}>
                  <TableCell>{bank.accountName}</TableCell>
                  <TableCell>{bank.accountNumber}</TableCell>
                  <TableCell>{bank.bankName}</TableCell>
                  <TableCell>{bank.accountType}</TableCell>
                  <TableCell>{bank.codeType}</TableCell>
                  <TableCell>{bank.bankCode}</TableCell>
                  <TableCell>
                    {bank.qrImage ? (
                      <img
                        src={URL.createObjectURL(bank.qrImage)}
                        alt="QR"
                        width={50}
                        height={50}
                      />
                    ) : (
                      'N/A'
                    )}
                  </TableCell>
                  <TableCell>
                    <IconButton
                      color="error"
                      onClick={() => handleDelete(index)} // Handle delete on button click
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default BankDetails;
