import { userTypes } from './config';

// Define the JSON object for each user role
const companyAdminMenu = [
  {
    label: 'Dashboard', // Analytics
    path: 'dashboard',
    icon: 'HomeIcon',
    children: [],
  },
  {
    label: 'Documents', //Doc mgmt
    path: 'documents',
    icon: 'Menu',
    children: [],
  },

  {
    label: 'Users', //Doc mgmt
    path: 'user-management',
    icon: 'Users',
    children: [],
  },
  {
    label: 'Onboarding',
    path: 'invite-user',
    icon: 'InviteUser',
    children: [
      {
        label: 'Invite Staff',
        path: 'invite-user/invite-staff',
        icon: 'GroupAddIcon',
      },
      {
        label: 'Invite Customer',
        path: 'invite-user/invite-customer',
        icon: 'PersonAddAlt1Icon',
      },
      {
        label: 'Invite Corporate',
        path: 'invite-user/invite-corporate',
        icon: 'PersonAddAlt1Icon',
      },
    ],
  },
  {
    label: 'Access Control',
    path: 'access-control',
    icon: 'PersonAddAlt1Icon',
    children: [
      {
        label: 'Staff groups',
        path: 'access-control/staff-groups',
        icon: 'GroupAddIcon',
      },
      {
        label: 'Customer group',
        path: 'access-control/create-customer-group',
        icon: 'GroupAddIcon',
      },
      {
        label: 'Mappings',
        path: 'access-control/mappings',
        icon: 'Mappings',
      },
    ],
  },
  {
    label: 'Invoice', //Doc mgmt
    path: 'invoice-management',
    icon: 'Invoice',
  },
  {
    label: 'Contract', //Doc mgmt
    path: 'contract-management',
    icon: 'Invoice',
  },
];

const superAdminMenu = [
  {
    label: 'Dashboard',
    path: 'dashboard',
    icon: 'HomeIcon',
    children: [],
  },
  {
    label: 'Contract Mgmt',
    path: 'contract-management',
    icon: 'Contract',
    children: [],
  },
];

const customerMenu = [
  {
    label: 'Dashboard',
    path: 'dashboard',
    icon: 'HomeIcon',
    children: [],
  },
  {
    label: 'Contract Mgmt',
    path: 'contract-management',
    icon: 'Contract',
    children: [],
  },
  {
    label: 'Invoice',
    path: 'invoice-management',
    icon: 'Invoice',
    children: [],
  },
];
const companyStaffMenu = [
  {
    label: 'Dashboard', // Analytics
    path: 'dashboard',
    icon: 'HomeIcon',
    children: [],
  },
  {
    label: 'Documents',
    path: 'documents',
    icon: 'Menu',
    children: [],
  },
];
const corporateStaffMenu = [
  {
    label: 'Dashboard',
    path: 'dashboard',
    icon: 'HomeIcon',
    children: [],
  },
  {
    label: 'Contract Mgmt',
    path: 'contract-management',
    icon: 'Contract',
    children: [],
  },
];
const corporateAdminMenu = [
  {
    label: 'Dashboard',
    path: 'dashboard',
    icon: 'HomeIcon',
    children: [],
  },
  {
    label: 'Contract Mgmt',
    path: 'contract-management',
    icon: 'Contract',
    children: [],
  },
  {
    label: 'Users', //Doc mgmt
    path: 'user-management',
    icon: 'Menu',
    children: [],
  },
  {
    label: 'Invoice', //Doc mgmt
    path: 'invoice-management',
    icon: 'Invoice',
  },
];

// Function to return the correct menu based on the user role
const getMenuForUser = (user: string | null) => {
  switch (user) {
    case userTypes.companyAdmin:
      return companyAdminMenu;
    case userTypes.individual:
      return customerMenu;
    case userTypes.companyStaff:
      return companyStaffMenu;
    case userTypes.superAdmin:
      return superAdminMenu;
    case userTypes.corporateAdmin:
      return corporateAdminMenu;
    case userTypes.corporateStaff:
      return corporateStaffMenu;
    default:
      return []; // Return an empty array or a default menu
  }
};

export default getMenuForUser; // Export the function only
