export function getRandomLightColor() {
  const colors = [
    '#FFDDC1', // Light Peach
    '#FFD1DC', // Light Pink
    '#FFECB3', // Light Yellow
    '#D4E157', // Light Lime Green
    '#B3E5FC', // Light Sky Blue
    '#C5CAE9', // Light Lavender
    '#F8BBD0', // Light Rose
    '#FFCCBC', // Light Coral
    '#DCEDC8', // Light Green
    '#E1F5FE', // Very Light Blue
    '#FFF9C4', // Light Lemon
    '#D7CCC8', // Light Beige
  ];

  const randomIndex = Math.floor(Math.random() * colors.length);
  return colors[randomIndex];
}
export function parseContractData(data: any) {
  return {
    contractDetails: {
      levels: data.map((item: any, index: number) => ({
        levelId: index + 1,
        signers: item.fields.map((field: any) => ({
          email: field.email,
          signed: false,
          timestamp: null,
          signerFullName: field.name,
        })),
        isCompleted: false,
        isLastLevel: index === data.length - 1,
        signedSoFar: 0,
        requiredSignatures: item.fields.length,
      })),
      contractId: '349c2aba-93fb-4774-9176-080e945de1en',
      originatorRole: 'Realtor',
      originatorEmail: 'tenettestuser2@gmail.com',
      documentTemplate: 'Rental Agreement',
      originatorUserName: 'Tenet Testuser2',
      contractName: 'My trial contract',
    },
  };
}
