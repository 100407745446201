import { userTypes } from 'config/config';
import PageNotFound from 'pages/404';
import { Route, Routes } from 'react-router-dom';
import AdminDashboard from 'screens/admin/AdminDashboard';
import PreferenceCorporate from 'screens/corporate/PreferenceCorporate';
import InvoiceManagement from 'screens/invoice/InvoiceManagement';
import ChangePassword from 'screens/password/ChangePassword';
import UserManagement from 'screens/userManagement/UserManagement';

import Layout from '../components/Layout';
import ProtectedRoute from './ProtectedRoute';

const CorporateAdminRoutes = () => (
  <ProtectedRoute allowedRoles={[userTypes.corporateAdmin]}>
    <Layout>
      <Routes>
        <Route
          path="dashboard/*"
          element={<AdminDashboard showUserSelection={false} />}
        />
        <Route path="preference" element={<PreferenceCorporate />} />
        <Route path="change-password" element={<ChangePassword />} />
        <Route path="user-management" element={<UserManagement />} />
        <Route path="/invoice-management" element={<InvoiceManagement />} />
        {/* <Route
          path="/invoice-management/invoice/:invoiceId"
          element={<ViewInvoice />}
        /> */}
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </Layout>
  </ProtectedRoute>
);
export default CorporateAdminRoutes;
