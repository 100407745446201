import { useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
} from '@mui/material';
import { previousFileVersionInterface } from 'common/interface';
import { FileData } from 'common/utils';
import { userTypes } from 'config/config';
import { popupNotification } from 'notifications/ToastNotification';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from 'redux/store';
import { folderNameRegex } from 'screens/superAdmin/validation';
import * as yup from 'yup';

import CustomModal from '../../components/CustomModal';
import CustomDropdown from '../../components/elements/CustomDropdown';
import CustomIconButton from '../../components/elements/IconButton';
import FileTable from '../../components/fileComponent/FileList';
import FileUploadModal from '../../components/fileComponent/FileUpload';
import PreviousVersionFileComp from '../../components/fileComponent/PreviousVersionFileComp';
import { useFileUpload } from '../../hooks/useFileUpload'; // Import the hook
import {
  getAllFileVersions,
  getPrivateSharedFiles,
  getUserFiles,
  getUsersByAction,
  handleCreateNewFolder,
} from './adminDashboardService';
// loading states interface
interface loadingStates {
  fetchingFiles: boolean;
  creatingFolder: boolean;
  loadingUsers: boolean;
  fetchingFileVersion: boolean;
}
// Define validation schema using Yup
const schema = yup
  .object({
    option: yup.string().required('This field is required'),
  })
  .required();

interface FormValues {
  option: string;
}
interface Props {
  showUserSelection?: boolean;
}

const AdminDashboard: React.FC<Props> = ({ showUserSelection = true }) => {
  const {
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<FormValues> = (data) => {};
  const { uuid, '*': dynamicPath } = useParams();
  const [users, setUsers] = useState([] as any[]);
  const [loading, setLoading] = useState({} as loadingStates);
  const [userFiles, setUserFiles] = useState([] as FileData[]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [newFolderName, setNewFolderName] = useState<string>('');
  const [selectedType, setSelectedType] = useState<string>('');
  const [showFileVersionModal, setShowFileVersionModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isPrivate, setIsPrivate] = useState(true);
  const [previousFileVersions, setPreviousFileVersions] = useState(
    [] as previousFileVersionInterface[]
  );
  const [selectedFileForVersion, setSelectFileForVersion] =
    useState<FileData>();
  const [path, setPath] = useState<string[]>(
    dynamicPath ? dynamicPath.split('/').filter(Boolean) : []
  );
  const { firstName, lastName, employeeId, role, corporateId } = useSelector(
    (state: RootState) => state.auth
  );
  const isCorporate =
    role === userTypes.corporateAdmin || role === userTypes.corporateStaff
      ? true
      : false;
  const selectedOption = watch('option');
  const newFolderPath = path.join('/');
  const [baseFolder, setBaseFolder] = useState('private-shared');
  const { uploadFiles, progress, isUploading, setSelectedFiles, files } =
    useFileUpload();
  const handleUpload = async () => {
    await uploadFiles(
      uuid,
      selectedOption || corporateId,
      firstName,
      lastName,
      newFolderPath,
      setModalOpen
    );
    getUserFiles(selectedOption || corporateId, setLoading, uuid, setUserFiles);
    setSelectedFiles([]);
  };

  const handlePrivateFileUpload = async () => {
    if (
      role === userTypes.corporateAdmin ||
      role === userTypes.corporateStaff
    ) {
      await uploadFiles(
        uuid,
        corporateId,
        firstName,
        lastName,
        newFolderPath,
        setModalOpen,
        'private-shared'
      );
    } else {
      await uploadFiles(
        uuid,
        null,
        firstName,
        lastName,
        newFolderPath,
        setModalOpen,
        'private-shared'
      );
    }
    await getPrivateSharedFiles(
      setLoading,
      setIsPrivate,
      setUserFiles,
      setPath,
      isCorporate
    );
  };

  const getFileVersions = async (file: FileData) => {
    await getAllFileVersions(
      file,
      setSelectFileForVersion,
      setShowFileVersionModal,
      setLoading,
      setPreviousFileVersions
    );
  };

  useEffect(() => {
    if (selectedOption) {
      setUserFiles([] as FileData[]);
      getUserFiles(selectedOption, setLoading, uuid, setUserFiles);
      setPath([]);
      setIsPrivate(false);
      setBaseFolder('Home');
    }
  }, [selectedOption]);
  useEffect(() => {
    if (
      (role === userTypes.corporateAdmin ||
        role === userTypes.corporateStaff) &&
      !isPrivate
    ) {
      setUserFiles([] as FileData[]);
      getUserFiles(corporateId, setLoading, uuid, setUserFiles);
      setPath([]);
      setIsPrivate(false);
      setBaseFolder('Home');
    }
  }, []);

  const createNewFolder = async () => {
    let userTypeId;
    if (
      role === userTypes.corporateAdmin ||
      role === userTypes.corporateStaff
    ) {
      userTypeId = corporateId;
    } else {
      userTypeId = selectedOption;
    }
    await handleCreateNewFolder({
      newFolderName,
      setLoading,
      isPrivate,
      uuid: uuid as string,
      selectedOption: userTypeId,
      baseFolder,
      newFolderPath,
      popupNotification,
      getUserFiles,
      setUserFiles,
      setNewFolderName,
      setShowModal,
      setIsPrivate,
      setPath,
      isCorporate,
    });
  };

  const handleFilesSelected = (files: File[]) => {
    setSelectedFiles(files); // Use the hook to set selected files
  };
  useEffect(() => {
    getPrivateSharedFiles(
      setLoading,
      setIsPrivate,
      setUserFiles,
      setPath,
      isCorporate
    );
  }, []);
  return (
    <>
      <Box width="100%" onSubmit={handleSubmit(onSubmit)} sx={{ mt: 3 }}>
        {showUserSelection ? (
          <Grid
            container
            p={2}
            sx={{
              boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
              borderRadius: 4,
            }}
          >
            <Grid item lg={4} md={12} sm={12}>
              <FormControl fullWidth>
                <FormLabel id="row-radio-buttons-group-label">
                  Which user type would you like to access?
                </FormLabel>

                <RadioGroup
                  row
                  aria-labelledby="row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel
                    value="GetIndividual"
                    control={<Radio />}
                    label="Individual"
                    disabled={loading.loadingUsers}
                    onChange={() => {
                      getUsersByAction(
                        'GetIndividual',
                        setLoading,
                        uuid,
                        employeeId,
                        setUsers
                      );
                      setSelectedType('individual');
                      setValue('option', '');
                      setUserFiles([]);
                      setPath([]);
                      setIsPrivate(false);
                      setBaseFolder('Home');
                    }}
                  />
                  <FormControlLabel
                    value="GetCorporate"
                    control={<Radio />}
                    label="Corporate"
                    disabled={loading.loadingUsers}
                    onChange={() => {
                      getUsersByAction(
                        'GetCorporate',
                        setLoading,
                        uuid,
                        employeeId,
                        setUsers
                      );
                      // getUsersByAction('GetCorporate');
                      setSelectedType('corporate');
                      setValue('option', '');
                      setUserFiles([]);
                      setPath([]);
                      setIsPrivate(false);
                      setBaseFolder('Home');
                    }}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item lg={8} md={6} sm={12}>
              {selectedType && (
                <CustomDropdown
                  name="option"
                  control={control}
                  options={users}
                  label={
                    selectedType === 'individual'
                      ? 'Select user'
                      : 'Select corporate'
                  }
                  labelKey="name"
                  valueKey="id"
                  error={errors.option}
                  loading={loading.loadingUsers}
                />
              )}
            </Grid>
          </Grid>
        ) : null}
        <Box display="flex" justifyContent="space-between" mt={4}>
          <Box>
            <Tooltip
              arrow
              title="This is a common area for staff to collaborate"
            >
              <Button
                size="small"
                variant={isPrivate ? 'contained' : 'outlined'}
                onClick={() => {
                  getPrivateSharedFiles(
                    setLoading,
                    setIsPrivate,
                    setUserFiles,
                    setPath,
                    isCorporate
                  );
                  setBaseFolder('private-shared');
                }}
              >
                {' '}
                Work area
              </Button>
            </Tooltip>
            <Button
              sx={{ marginLeft: 4 }}
              size="small"
              disabled={
                !selectedOption &&
                (role === userTypes.companyAdmin ||
                  role === userTypes.companyStaff)
              }
              variant={isPrivate ? 'outlined' : 'contained'}
              onClick={() => {
                setIsPrivate(false);
                getUserFiles(
                  selectedOption || corporateId, //if company admin or staff then use from drop-down else use corporateId instead
                  setLoading,
                  uuid,
                  setUserFiles
                );
                setBaseFolder('Home');
              }}
            >
              {isCorporate ? 'Collaborate' : 'View individual/corporate space'}
            </Button>
          </Box>
          <Box>
            <CustomIconButton
              tooltipTitle="Create folder"
              buttonType="primary"
              size="small"
              onClick={() => setShowModal(true)}
            >
              <CreateNewFolderIcon />
            </CustomIconButton>
            <CustomIconButton
              tooltipTitle="Upload file"
              buttonType="primary"
              size="small"
              onClick={() => setModalOpen(true)}
            >
              <UploadFileIcon />
            </CustomIconButton>
          </Box>
        </Box>

        <FileUploadModal
          open={isModalOpen}
          onClose={() => {
            setModalOpen(false);
            setSelectedFiles([]); // Clear files when modal closes
          }}
          onFilesSelected={handleFilesSelected}
          progress={progress}
          onUpload={isPrivate ? handlePrivateFileUpload : handleUpload}
          uploading={isUploading} // Use uploading state from the hook
          files={files} // Use files state from the hook
        />
        <FileTable
          userFiles={userFiles}
          path={path}
          setPath={setPath}
          getFileVersions={getFileVersions}
          loading={loading.fetchingFiles}
          isPrivate={isPrivate}
        />
      </Box>

      <CustomModal
        open={showModal}
        onClose={() => {
          setShowModal(false);
          setNewFolderName('');
        }}
        type="info"
        autoClose={false}
        primaryButtonLoading={loading.creatingFolder}
        title="Create new folder"
        primaryButtonLabel="Ok"
        secondaryButtonAction={() => {
          setShowModal(false);
          setNewFolderName('');
        }}
        secondaryButtonLabel="Cancel"
        primaryButtonAction={createNewFolder}
      >
        <TextField
          fullWidth
          label="Folder Name"
          autoFocus
          required
          size="small"
          variant="outlined"
          value={newFolderName}
          onChange={(e) => setNewFolderName(e.target.value)}
        />
        {!folderNameRegex.test(newFolderName) && !!newFolderName ? (
          <FormHelperText error>
            Folder name can contain alphabets, digits, and - _ & symbols
          </FormHelperText>
        ) : (
          ''
        )}
      </CustomModal>

      <PreviousVersionFileComp
        folderId={selectedOption}
        setPreviousFileVersions={setPreviousFileVersions}
        showFileVersionModal={showFileVersionModal}
        setShowFileVersionModal={setShowFileVersionModal}
        loading={loading.fetchingFileVersion}
        previousFileVersions={previousFileVersions}
        fileName={selectedFileForVersion?.name || ''}
      />
    </>
  );
};

export default AdminDashboard;
