import { useState } from 'react';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Typography } from '@mui/material';

import CustomIconButton from './elements/IconButton';
interface CopyToClipboardProps {
  copyText: string;
}

const CopyToClipboard: React.FC<CopyToClipboardProps> = ({ copyText }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(copyText);
      setCopied(true);
      setTimeout(() => setCopied(false), 5000); // Reset after 2 seconds
    } catch (error) {
      console.error('Failed to copy:', error);
    }
  };

  return (
    <div>
      {copied ? (
        <Typography variant="subtitle2" color="primary">
          Copied
        </Typography>
      ) : (
        <CustomIconButton
          onClick={handleCopy}
          buttonType="primary"
          tooltipTitle="Copy"
        >
          <ContentCopyIcon />
        </CustomIconButton>
      )}

      {/* <button onClick={handleCopy}>
        {copied ? 'Copied!' : 'Copy to Clipboard'}
      </button> */}
    </div>
  );
};

export default CopyToClipboard;
