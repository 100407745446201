import React, { ReactNode, useState } from 'react';

import { Box, CssBaseline } from '@mui/material';
import { Outlet } from 'react-router-dom';

import Navigation from './Navigation';
import { Sidebar } from './Sidebar';

interface LayoutProps {
  children?: ReactNode; // Accept children as a ReactNode
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  const handleToggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        width: '100vw',
        backgroundColor: '#f8f9fa',
      }}
    >
      <CssBaseline />
      <Navigation />
      <Box sx={{ display: 'flex', flexGrow: 1, mt: 4 }}>
        <Sidebar
          isCollapsed={isCollapsed}
          onToggleCollapse={handleToggleCollapse}
        />
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            padding: '2rem',
            transition: 'padding-left 0.3s ease',
            paddingLeft: isCollapsed ? '70px' : '2rem',
            mt: 6,
            backgroundColor: '#fff',
            borderRadius: 8,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
          }}
        >
          {children || <Outlet />}
        </Box>
      </Box>
    </Box>
  );
};

export default Layout;
