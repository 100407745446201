import { useEffect, useState } from 'react';

import { Tab, Tabs } from '@mui/material';
import HeaderComp from 'components/elements/HeadingComponent';
import { userTypes } from 'config/config';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';

import AddCustomersForInvoice from './components/createInvoiceComp/AddCustomersForInvoice';
import CreateTemplates from './components/createTemplateComp/CreateTemplates';
import InvoicePage from './components/InvoicePage';
import CustomerInvoiceList from './customer/CustomerInvoiceList';
import ViewInvoice from './customer/ViewInvoice';
import useInvoiceTab from './hooks/useTab';
import InvoiceList from './update/InvoiceList';
import UpdateInvoicePage from './update/UpdateInvoice';

const tabOptionsAdmin = [
  'Create invoice template',
  'Add customer details',
  'Create invoice',
  'Update invoice',
];
const tabOptions = ['My invoices'];
const InvoiceManagement = () => {
  // const [activeTab, setActiveTab] = useState(0);
  const { activeTab, setActiveTab, handleTabChange } = useInvoiceTab();
  const [tabsList, setTabsList] = useState([] as string[]);
  const [invoiceId, setInvoiceId] = useState('' as string);
  const { role } = useSelector((state: RootState) => state.auth);
  useEffect(() => {
    if (role === userTypes.companyAdmin) {
      setTabsList(tabOptionsAdmin);
    } else {
      setTabsList(tabOptions);
    }
  }, []);
  return (
    <div>
      <HeaderComp>Invoice management</HeaderComp>
      {invoiceId && role === userTypes.companyAdmin ? (
        <UpdateInvoicePage invoiceId={invoiceId} setInvoiceId={setInvoiceId} />
      ) : null}
      {invoiceId && role !== userTypes.companyAdmin ? (
        <ViewInvoice invoiceId={invoiceId} setInvoiceId={setInvoiceId} />
      ) : null}
      {!invoiceId ? (
        <>
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            textColor="primary"
            indicatorColor="primary"
            aria-label="secondary tabs example"
          >
            {tabsList.map((tab, index) => {
              return <Tab value={index} label={tab} key={tab} />;
            })}
          </Tabs>

          {activeTab === 0 && role === userTypes.companyAdmin && (
            <CreateTemplates />
          )}
          {activeTab === 0 && role !== userTypes.companyAdmin && (
            <CustomerInvoiceList setInvoiceId={setInvoiceId} />
          )}
          {activeTab === 1 && <AddCustomersForInvoice />}
          {activeTab === 2 && <InvoicePage />}
          {activeTab === 3 && <InvoiceList setInvoiceId={setInvoiceId} />}
        </>
      ) : null}
    </div>
  );
};

export default InvoiceManagement;
