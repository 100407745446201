import React, { ChangeEvent, useState } from 'react';

import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Checkbox,
  Divider,
  InputAdornment,
  List,
  ListItemButton,
  ListItemText,
  TextField,
  Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import colors from 'theme/colors';

import Loader from './elements/Loader';

// Helper function to get the value of nested properties from an object
const getValueByKey = (obj: any, key: string | keyof any): any => {
  return key
    .toString()
    .split('.')
    .reduce((o, i) => o[i], obj);
};

interface SelectableListProps<T> {
  items: T[]; // Generic items type
  toggleItem: (itemId: string) => void;
  selectedItems: string[];
  idKey: keyof T | string; // Key for the ID field, can be nested
  labelKey: keyof T | string; // Key for the label field, can be nested
  searchVisible?: boolean;
  showCheckBoxIcon?: boolean;
  showDivider?: boolean;
  searchPlaceHolder?: string;
  loading?: boolean;
  allowSingleSelection?: boolean;
  showSelectHighlight?: boolean;
  height?: number; // Add height as a prop for scrollable behavior
}

const SelectableList = <T extends Record<string, any>>({
  items,
  toggleItem,
  selectedItems,
  idKey,
  labelKey,
  searchVisible = true,
  showCheckBoxIcon = true,
  showDivider = false,
  searchPlaceHolder = 'Start typing to search...',
  loading = false,
  allowSingleSelection = false,
  showSelectHighlight = false,
  height = 200,
}: SelectableListProps<T>) => {
  const [searchTerm, setSearchTerm] = useState<string>('');

  // Handler for search input change
  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  // Filter items based on search term (case-insensitive) and labelKey
  const filteredItems = items.filter((item) =>
    String(getValueByKey(item, labelKey))
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
  );

  // Handler for item click
  const handleItemClick = (itemId: string, isSelected: boolean) => {
    if (allowSingleSelection) {
      if (selectedItems.length) {
        toggleItem(selectedItems[0]);
      }
      if (!isSelected) {
        toggleItem(itemId);
      }
    } else {
      toggleItem(itemId);
    }
  };

  return (
    <Box
      p={2}
      height="100%"
      sx={{
        borderRadius: 4,
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
      }}
    >
      {searchVisible && (
        <TextField
          fullWidth
          // label="Search"
          size="small"
          variant="outlined"
          value={searchTerm}
          placeholder={searchPlaceHolder}
          onChange={handleSearchChange}
          sx={{ mb: 2 }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" sx={{ mt: 1 }}>
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      )}

      <Box
        sx={{
          height: 350, // Set height dynamically based on the provided prop
          overflowY: 'auto', // Enable vertical scrolling
          width: '100%',
        }}
      >
        {loading ? (
          <Loader />
        ) : filteredItems.length === 0 ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
          >
            <Typography variant="body1" color="text.disabled">
              Nothing here
            </Typography>
          </Box>
        ) : (
          <List>
            {filteredItems.map((item, index) => {
              const itemId = String(getValueByKey(item, idKey));
              const itemLabel = String(getValueByKey(item, labelKey));
              const isSelected = selectedItems.includes(itemId);

              return (
                <React.Fragment key={itemId}>
                  <ListItemButton
                    onClick={() => handleItemClick(itemId, isSelected)}
                    sx={{
                      cursor: 'pointer',
                      mb: 1,
                      pb: 0.5,
                      pt: 0.5,
                      color:
                        isSelected && showSelectHighlight ? '#fff' : '#000',
                      borderRadius: 2,
                      backgroundColor: isSelected
                        ? showSelectHighlight
                          ? (theme) => theme.palette.info.light
                          : 'inherit'
                        : 'inherit',
                      '&:hover': {
                        backgroundColor: (theme) =>
                          !isSelected && showSelectHighlight // Check if not selected and showSelectHighlight is true
                            ? grey[200] // Show lightgray on hover when not selected
                            : isSelected && !showSelectHighlight
                              ? 'inherit' // No hover effect if selected and highlight is off
                              : colors.highlight, // Show lightgray when not selected
                      },
                    }}
                  >
                    {showCheckBoxIcon && (
                      <Checkbox checked={isSelected} sx={{ ml: -2.8 }} />
                    )}
                    <ListItemText>
                      <Typography variant="body2">{itemLabel}</Typography>
                    </ListItemText>
                    {/* <ListItemText primary={itemLabel} /> */}
                  </ListItemButton>
                  {showDivider && index < filteredItems.length - 1 && (
                    <Divider />
                  )}
                </React.Fragment>
              );
            })}
          </List>
        )}
      </Box>
    </Box>
  );
};

export default SelectableList;
