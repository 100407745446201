import React, { useState } from 'react';

import { useDrag, useDrop } from 'react-dnd';
import Draggable from 'react-draggable';
import { Document, Page, pdfjs } from 'react-pdf';

// pdfjs.GlobalWorkerOptions.workerSrc =
//   'https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js';
pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@4.8.69/build/pdf.worker.min.mjs`;
interface SignatureField {
  id: string;
  x: number;
  y: number;
  width: number;
  height: number;
  signature: string | null;
  page: number;
}

interface PDFViewerSignProps {
  pdfFile: File | null;
  setPdfFile: (file: File | null) => void;
}

const DraggableSignature: React.FC = () => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'signature',
    item: {},
    collect: (monitor) => ({ isDragging: monitor.isDragging() }),
  }));

  return (
    <div
      ref={drag}
      style={{
        width: '120px',
        height: '40px',
        border: '1px solid black',
        backgroundColor: 'lightgray',
        textAlign: 'center',
        lineHeight: '40px',
        cursor: 'grab',
        opacity: isDragging ? 0.5 : 1,
      }}
    >
      Drag Signature
    </div>
  );
};

const PDFViewerSign: React.FC<PDFViewerSignProps> = ({
  pdfFile,
  setPdfFile,
}) => {
  const [signatureFields, setSignatureFields] = useState<SignatureField[]>([]);
  const [selectedField, setSelectedField] = useState<SignatureField | null>(
    null
  );
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [numPages, setNumPages] = useState<number | null>(null);

  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: 'signature',
    drop: (item: any, monitor) => {
      const offset = monitor.getSourceClientOffset();
      if (!offset) return;

      const newSignatureField: SignatureField = {
        id: Date.now().toString(),
        x: offset.x,
        y: offset.y,
        width: 120,
        height: 40,
        signature: null,
        page: 1, // Defaulting to page 1 for now
      };
      setSignatureFields((prev) => [...prev, newSignatureField]);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }));

  const handleSignatureSelect = (signature: string) => {
    setSignatureFields((prev) =>
      prev.map((field) =>
        field.id === selectedField?.id ? { ...field, signature } : field
      )
    );
    setModalOpen(false);
  };

  return (
    <div style={{ position: 'relative' }}>
      <>
        <DraggableSignature />
        <div
          ref={drop}
          style={{ position: 'relative', border: '2px dashed gray' }}
        >
          <Document
            file={pdfFile}
            onLoadSuccess={({ numPages }) => setNumPages(numPages)}
          >
            {Array.from({ length: numPages || 0 }, (_, index) => (
              <div key={`page-${index + 1}`} style={{ position: 'relative' }}>
                <Page pageNumber={index + 1} />

                {signatureFields
                  .filter((field) => field.page === index + 1)
                  .map((field) => (
                    <Draggable
                      key={field.id}
                      defaultPosition={{ x: field.x, y: field.y }}
                    >
                      <div
                        onClick={() => {
                          setSelectedField(field);
                          setModalOpen(true);
                        }}
                        style={{
                          position: 'absolute',
                          width: `${field.width}px`,
                          height: `${field.height}px`,
                          border: '2px solid red',
                          backgroundColor: 'white',
                          textAlign: 'center',
                          lineHeight: '40px',
                          cursor: 'pointer',
                        }}
                      >
                        {field.signature ? field.signature : 'Sign Here'}
                      </div>
                    </Draggable>
                  ))}
              </div>
            ))}
          </Document>
        </div>

        {isModalOpen && (
          <div
            style={{
              position: 'absolute',
              top: '30%',
              left: '30%',
              padding: '20px',
              backgroundColor: 'white',
              border: '1px solid #ddd',
              zIndex: 3,
            }}
          >
            <h3>Select a Signature</h3>
            <button onClick={() => handleSignatureSelect('Signature 1')}>
              Signature 1
            </button>
            <button onClick={() => handleSignatureSelect('Signature 2')}>
              Signature 2
            </button>
            <button onClick={() => setModalOpen(false)}>Cancel</button>
          </div>
        )}
      </>
    </div>
  );
};

export default PDFViewerSign;
