import React from 'react';

import { Box, Tab, Tabs } from '@mui/material';
import HeaderComp from 'components/elements/HeadingComponent';
import { userTypes } from 'config/config';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import PreferenceAdmin from 'screens/admin/PreferenceAdmin';
import PreferenceCorporate from 'screens/corporate/PreferenceCorporate';
import PreferenceStaff from 'screens/staff/PreferenceStaff';

const Preference: React.FC = () => {
  const [value, setValue] = React.useState<number>(0);

  const { role } = useSelector((state: RootState) => state.auth);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const getTab1Content = () => {
    switch (role) {
      case userTypes.companyAdmin:
        return <PreferenceAdmin />;
      case userTypes.corporateAdmin:
        return <PreferenceCorporate />;
      case userTypes.corporateStaff:
        return <PreferenceStaff />;
      case userTypes.companyStaff:
        return <PreferenceStaff />;
    }
  };

  return (
    <>
      <HeaderComp>Preferences</HeaderComp>
      <Tabs value={value} onChange={handleChange} orientation="horizontal">
        <Tab disableRipple label="User" sx={{ textTransform: 'none' }} />
        <Tab disableRipple label="Payments" sx={{ textTransform: 'none' }} />
      </Tabs>
      <Box>
        {value === 0 && getTab1Content()}
        {value === 1 && <Box mt={5}>Payment QR code</Box>}
      </Box>
    </>
  );
};

export default Preference;
