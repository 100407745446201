import { useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Box, IconButton, Typography } from '@mui/material';
import {
  DataGrid,
  GridCsvExportOptions,
  GridToolbarContainer,
  GridToolbarExport,
} from '@mui/x-data-grid';
import { APIEndpoint } from 'common/enum';
import {
  universalGetRequest,
  universalPutRequest,
} from 'common/requestHandler';
import CustomModal from 'components/CustomModal';
import InputElement from 'components/elements/InputElement';
import OverlayLoader from 'components/elements/OverlayLoader';
import dayjs from 'dayjs';
import useNavigateWithUUID from 'hooks/useNavigateWithUUID';
import { popupNotification } from 'notifications/ToastNotification';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { validateChangeInvoiceStatus } from 'validation/validationScheme';

import { invoiceListInterface, markPaidInterface } from '../types';

interface Props {
  setInvoiceId: React.Dispatch<React.SetStateAction<string>>;
}
const CustomerInvoiceList: React.FC<Props> = ({ setInvoiceId }) => {
  const [invoice, setInvoice] = useState([] as invoiceListInterface[]);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const { companyId } = useSelector((state: RootState) => state.auth);
  const [closingComments, setClosingComments] = useState('');
  const [amount, setAmount] = useState('' as unknown as number);
  const { navigateWithUuid } = useNavigateWithUUID();
  const [activeRow, setActiveRow] = useState({} as invoiceListInterface);
  const { control, handleSubmit, reset } = useForm<markPaidInterface>({
    resolver: yupResolver(validateChangeInvoiceStatus(activeRow.amountDue)),
    mode: 'onChange',
    defaultValues: {
      closingComments: '',
      amount: '' as unknown as number,
    },
  });

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });

  const handleViwInvoice = (row: any) => {
    setActiveRow(row);
    setInvoiceId(row.invoiceId);
    // navigateWithUuid(`invoice-management/invoice/${row.invoiceId}`);
  };
  const csvOptions: GridCsvExportOptions = {
    delimiter: ',',
    utf8WithBom: true,
    fields: [
      'invoiceNo',
      'dateCreated',
      'customerName',
      'amount',
      'amountDue',
      'dateDue',
      'paymentStatus',
    ], // Export only these columns
  };

  const columns = [
    {
      field: 'action',
      headerName: 'Action',
      filterable: false,
      sortable: false,
      renderCell: (params: any) => {
        return (
          <Box display="flex" alignItems="center" width="100%" height="100%">
            <IconButton onClick={() => handleViwInvoice(params.row)}>
              <VisibilityIcon />
            </IconButton>
          </Box>
        );
      },
    },
    { field: 'invoiceNo', headerName: 'Invoice No', width: 150 },
    { field: 'dateCreated', headerName: 'Date Created', width: 150 },
    {
      field: 'customerName',
      headerName: 'Customer Name',
      width: 200,

      renderCell: (params: any) => {
        return (
          <Box display="flex" alignItems="center" width="100%" height="100%">
            <Typography textTransform="capitalize">
              {params.row.customerName}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: 'amount',
      headerName: 'Total Amount',
      renderCell: (params: any) => (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="100%"
        >
          <Typography variant="body2">
            {params.row?.amount?.toFixed(2)}
          </Typography>
        </Box>
      ),
    },
    {
      field: 'amountDue',
      headerName: 'Amount Due',
      textAlign: 'center',
      renderCell: (params: any) => (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="100%"
        >
          <Typography
            variant="body2"
            color={activeRow.amountDue == 0 ? 'success' : 'error'}
          >
            {params.row?.amountDue?.toFixed(2)}
          </Typography>
        </Box>
      ),
    },
    { field: 'dateDue', headerName: 'Date due', width: 100 },
    {
      field: 'amountPaid',
      headerName: 'Amount Paid',
      renderCell: (params: any) => (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="100%"
        >
          <Typography variant="body2">
            {params.row?.amountPaid?.toFixed(2)}
          </Typography>
        </Box>
      ),
    },
    {
      field: 'paid',
      headerName: 'Status',
      width: 150,
      renderCell: (params: any) => {
        return (
          <Box display="flex" alignItems="center" height="100%">
            {params.row.paid ? (
              <Typography color="success">Paid</Typography>
            ) : (
              <Typography color="error">Unpaid</Typography>
            )}
          </Box>
        );
      },
    },
  ];

  const handlePayment = (row: invoiceListInterface) => {
    setActiveRow(row);
    setShowModal(true);
  };

  const handlePaginationModelChange = (newPaginationModel: any) => {
    setPaginationModel(newPaginationModel);
  };
  const getInvoiceList = async () => {
    const response: any = await universalGetRequest(
      APIEndpoint.getSecDocsMain,
      {
        httpMethod: 'GET',
        actionName: 'GetMyInvoiceDetails',
      }
    );
    setLoading(false);
    if ('error' in response) {
      popupNotification('Something went wrong, try later', false);
    } else {
      setInvoice(response.data);
    }
  };
  const changePaymentStatus = async (data: markPaidInterface) => {
    setLoading(true);
    setShowModal(false);
    reset();
    const response = await universalPutRequest(APIEndpoint.secDocsMain, {
      httpMethod: 'PUT',
      actionName: 'InvoicePaid',
      closingComments: data.closingComments,
      amountPaid: data.amount,
      datePaid: dayjs().format('YYYY-MM-DD'),
      invoiceId: activeRow.invoiceId,
    });
    if ('error' in response) {
      popupNotification(response.error, false);
    } else {
      popupNotification(response.message, true);
      getInvoiceList();
    }
    setLoading(false);
  };
  useEffect(() => {
    getInvoiceList();
  }, []);

  return (
    <Box style={{ height: 400, width: '100%' }} mt={5}>
      <OverlayLoader loading={loading} text="Loading..." />
      <DataGrid
        rows={invoice}
        columns={columns}
        pagination
        getRowId={({ invoiceId }) => invoiceId}
        paginationModel={paginationModel}
        onPaginationModelChange={handlePaginationModelChange}
        pageSizeOptions={[10, 20, 50]}
        slots={{
          toolbar: () => (
            <GridToolbarContainer
              sx={{ display: 'flex', justifyContent: 'flex-end' }}
            >
              <GridToolbarExport
                csvOptions={csvOptions}
                printOptions={{ disableToolbarButton: true }}
              />
            </GridToolbarContainer>
          ), // Pass options here
        }}
      />
      <CustomModal
        primaryButtonAction={handleSubmit(changePaymentStatus)}
        primaryButtonLabel="Submit"
        autoClose={false}
        open={showModal}
        type="info"
        onClose={() => {
          setShowModal(false);
        }}
        title="Mark as paid"
      >
        <form onSubmit={handleSubmit(changePaymentStatus)}>
          <Typography variant="body2" color="textDisabled" gutterBottom>
            Please provide the closing comments and amount
          </Typography>

          {/* Closing Comments */}
          <InputElement
            name="closingComments"
            size="small"
            control={control}
            label="Closing Comments"
            type="text"
            required
            placeholder="Closing comments"
            multiline
            autoFocus
            rows={3}
            rules={{ required: 'Closing comments are required' }}
          />

          {/* Amount */}
          <InputElement
            name="amount"
            size="small"
            control={control}
            required
            label="Enter amount paid"
            placeholder="Enter amount paid"
            type="number"
            rules={{
              required: 'Amount is required',
              min: { value: 1, message: 'Amount must be greater than 0' },
            }}
          />
        </form>
      </CustomModal>
    </Box>
  );
};

export default CustomerInvoiceList;
