import React from 'react';

import {
  Box,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from '@mui/material';
import { NavLink, useMatch, useResolvedPath } from 'react-router-dom';
import colors from 'theme/colors';

interface NavListItemProps {
  icon: React.ReactNode; // This will be the icon component
  label: string; // The text label for the list item
  path: string; // The URL path
  generateUrlWithUuid: (path: string) => string; // Function to generate the URL with uuid
  onClick?: () => void; // Optional click handler for submenu
  endIcon?: React.ReactNode; // Optional end icon (like expand/collapse chevron)
  sx?: object; // Optional additional styles (for submenu indentation)
  isCollapsed?: boolean; // For collapsed sidebar
}

const NavListItem: React.FC<NavListItemProps> = ({
  icon,
  label,
  path,
  generateUrlWithUuid,
  onClick, // Optional submenu handler
  endIcon, // Optional end icon for expanding/collapsing
  sx = {}, // Optional styles like padding
  isCollapsed = false, // Sidebar collapsed state
}) => {
  const resolvedPath = useResolvedPath(generateUrlWithUuid(path));
  const match = useMatch({ path: resolvedPath.pathname, end: false });
  return (
    <Box sx={{ borderRadius: 5, p: 1, ...sx }}>
      <ListItem
        component={NavLink}
        to={generateUrlWithUuid(path)}
        onClick={onClick}
        sx={{
          p: 1,
          display: 'flex',
          alignItems: 'center',
          textDecoration: 'none',
          backgroundColor: match ? 'white' : 'transparent', // Active state background
          borderRadius: 5,
          justifyContent: 'space-between', // Push endIcon to the far end
          '&:hover': {
            backgroundColor: !isCollapsed ? colors.sidebarHoverColor : 'none', // Light blue on hover
          },
        }}
      >
        <ListItemIcon sx={{ minWidth: 'unset', mr: isCollapsed ? 0 : 2 }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: 30, // Icon container width
              height: 30, // Icon container height
              backgroundColor: colors.primary, // Icon background color
              borderRadius: 3, // Rounded icon container
              color: 'white', // Icon color
            }}
          >
            <Tooltip
              title={label}
              placement="right"
              arrow
              componentsProps={{
                tooltip: {
                  sx: {
                    bgcolor: colors.primary,
                    '& .MuiTooltip-arrow': {
                      color: 'white',
                    },
                    fontWeight: 600,
                  },
                },
              }}
            >
              <IconButton sx={{ color: 'white' }}>{icon}</IconButton>
            </Tooltip>
            {/* {icon} */}
          </Box>
        </ListItemIcon>
        {/* Only show the label if the sidebar is not collapsed */}
        {!isCollapsed && (
          <ListItemText
            primary={label}
            primaryTypographyProps={{
              sx: {
                color: match ? 'black' : '#A0AEC0', // Text color for active/inactive
                fontWeight: match ? 400 : 'normal', // Bold for active, normal for inactive
              },
              variant: 'body2',
            }}
          />
        )}
        {/* Optional end icon, typically for submenu expand/collapse */}
        {endIcon && !isCollapsed && (
          <Box component="span" sx={{ display: 'flex', alignItems: 'center' }}>
            {endIcon}
          </Box>
        )}
      </ListItem>
    </Box>
  );
};

export default NavListItem;
