import React, { useEffect, useState } from 'react';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import colors from 'theme/colors';

interface StepProgressProps {
  steps: number;
  currentStep: number;
  labels?: string[];
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
}

const StepProgress: React.FC<StepProgressProps> = ({
  steps,
  currentStep,
  labels,
  setCurrentStep,
}) => {
  const [animatedStep, setAnimatedStep] = useState(currentStep);
  const [activeStep, setActiveStep] = useState(currentStep);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (animatedStep < activeStep) {
      const timer = setInterval(() => {
        setProgress((prev) => {
          if (prev < 100) {
            return prev + 3;
          }
          clearInterval(timer);
          return 100;
        });
      }, 100);
      return () => clearInterval(timer);
    }
  }, [animatedStep, activeStep]);
  useEffect(() => {
    setActiveStep(currentStep);
    setAnimatedStep(currentStep);
  }, [currentStep]);

  useEffect(() => {
    if (progress === 100 && animatedStep < activeStep) {
      setAnimatedStep((prev) => prev + 1);
      setProgress(0);
    }
  }, [progress, animatedStep, activeStep]);

  const handleStepClick = (index: number) => {
    if (index <= currentStep) {
      setActiveStep(index);
      setAnimatedStep(index);
    }
  };
  useEffect(() => {
    setActiveStep(currentStep);
  }, [currentStep]);
  return (
    <div className="step-progress">
      <div className="step-container">
        {[...Array(steps)].map((_, index) => (
          <React.Fragment key={index}>
            <div
              onClick={() => handleStepClick(index)}
              className={`step-circle ${
                index < animatedStep
                  ? 'completed'
                  : index === animatedStep
                    ? 'active'
                    : ''
              } ${index > currentStep ? 'disabled' : ''}`}
            >
              {index < animatedStep ? (
                <CheckCircleIcon className="icon" color="primary" />
              ) : (
                <span>{index + 1}</span>
              )}
            </div>
            {index < steps - 1 && (
              <div className="step-connector">
                <div
                  className="progress-bar"
                  style={{
                    width:
                      index < animatedStep
                        ? '100%'
                        : index === animatedStep
                          ? `${progress}%`
                          : '0%',
                  }}
                ></div>
              </div>
            )}
          </React.Fragment>
        ))}
      </div>
      {labels && (
        <div className="step-labels">
          {[...Array(steps)].map((_, index) => (
            <p
              key={index}
              className={
                index < animatedStep ? 'completed-label' : 'pending-label'
              }
            >
              {labels[index] || `Step ${index + 1}`}
            </p>
          ))}
        </div>
      )}
      <style>{`
                .step-progress {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    width: 100%;
                }
                .step-container {
                    display: flex;
                    align-items: center;
                    width: 100%;
                }
                .step-circle {
                    width: 22px;
                    height: 22px;
                    border-radius: 50%;
                    background-color: rgb(236, 232, 232);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: relative;
                    font-size: 14px;
                    font-weight: bold;
                    cursor: pointer;
                }
                .step-circle.active {
                    background-color: ${colors.primary};
                    animation: pulse 1.5s infinite;
                    color: white;
                }
                .step-circle.completed {
                    background-color: transparent;
                }
                .step-circle.disabled {
                    cursor: not-allowed;
                    opacity: 0.5;
                }
               
                .step-connector {
                    flex: 1;
                    height: 2px;
                    background-color: #e3e3e3;
                    position: relative;
                    overflow: hidden;
                }
                .progress-bar {
                    height: 100%;
                    background-color: ${colors.primary};
                    transition: width 0.1s ease-in-out;
                }
                .step-labels {
                    display: flex;
                    width: 100%;
                    margin-top: 10px;
                    justify-content: space-between;
                }
                .completed-label {
                    color: green;
                    font-size: 14px;
                }
                .pending-label {
                    color: black;
                    font-size: 14px;
                }
                @keyframes pulse {
                    0% {
                        box-shadow: 0 0 0 0px rgba(44, 105, 185, 0.2);
                    }
                    100% {
                        box-shadow: 0 0 0 20px rgba(46, 91, 216, 0);
                    }
                }
            `}</style>
    </div>
  );
};

export default StepProgress;
