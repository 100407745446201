import { useEffect, useRef, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Edit, Save } from '@mui/icons-material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { APIEndpoint } from 'common/enum';
import {
  universalGetRequest,
  universalPostRequest,
  universalPutRequest,
} from 'common/requestHandler';
import CustomModal from 'components/CustomModal';
import CustomIconButton from 'components/elements/IconButton';
import InputElement from 'components/elements/InputElement';
import OverlayLoader from 'components/elements/OverlayLoader';
import dayjs from 'dayjs';
import { popupNotification } from 'notifications/ToastNotification';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from 'redux/store';
import { validateChangeInvoiceStatus } from 'validation/validationScheme';

import CompanyDetailsComp from '../components/createInvoiceComp/CompanyDetailsComp';
import CustomerDetailsComp from '../components/createInvoiceComp/CustomerDetailsComp';
import PaymentDetailsComp from '../components/createInvoiceComp/PaymentDetailsComp';
import SmallDateComp from '../components/DateComp';
import Footer from '../components/Footer';
import ProductTable from '../components/Producttable';
import useInvoiceTab from '../hooks/useTab';
import {
  CompanyDataInterface,
  InvoiceProductRow,
  markPaidInterface,
  paymentInfoInterface,
  savedInvoiceInterface,
} from '../types';
import {
  formatAmount,
  getCompanyDetailsForInvoiceCreation,
  saveComponentAsPDF,
} from '../utils';

const header = 'Invoice';

interface Props {
  invoiceId: string;
  setInvoiceId: React.Dispatch<React.SetStateAction<string>>;
}
const UpdateInvoicePage: React.FC<Props> = ({ invoiceId, setInvoiceId }) => {
  const [invoiceDetails, setInvoiceDetails] = useState<savedInvoiceInterface>({
    address: '',
    paymentTerms: '',
    invoiceNo: dayjs().format('YYMMDDHHmmss'),
    invoiceDate: dayjs(),
    dueDate: null,
    flatDiscount: 0,
    amountAfterDiscount: 0,
  } as savedInvoiceInterface);
  const [data, setData] = useState({} as CompanyDataInterface);
  const [rows, setRows] = useState<InvoiceProductRow[]>([]);
  const [isSaved, setIsSaved] = useState(true);
  const [editCompanyAddress, setEditCompanyAddress] = useState(true);
  const invoiceRef = useRef<HTMLDivElement | null>(null);
  const [isPaid, setIsPaid] = useState(false);
  const [isFetching, setIsFetching] = useState(true);
  const { companyId } = useSelector((state: RootState) => state.auth);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [paymentInfo, setPaymentInfo] = useState({} as paymentInfoInterface);
  // const { invoiceId } = useParams();
  const { activeTab, setActiveTab, handleTabChange } = useInvoiceTab();
  const navigate = useNavigate();

  const changePaymentStatus = async (data: markPaidInterface) => {
    setLoading(true);
    setShowModal(false);
    reset();
    const response = await universalPutRequest(APIEndpoint.secDocsMain, {
      httpMethod: 'PUT',
      actionName: 'InvoicePaid',
      closingComments: data.closingComments,
      amountPaid: data.amount,
      datePaid: dayjs().format('YYYY-MM-DD'),
      invoiceId: invoiceId,
    });
    if ('error' in response) {
      popupNotification(response.error, false);
    } else {
      popupNotification(response.message, true);
    }
    setLoading(false);
  };
  const { control, handleSubmit, reset } = useForm<markPaidInterface>({
    resolver: yupResolver(validateChangeInvoiceStatus(paymentInfo.amountDue)),
    mode: 'onChange',
    defaultValues: {
      closingComments: '',
      amount: '' as unknown as number,
    },
  });
  const getInvoice = async () => {
    const response: any = await universalGetRequest(
      APIEndpoint.getSecDocsMain,
      {
        invoiceId,
        httpMethod: 'GET',
        actionName: 'GetFullInvoice',
      }
    );
    if ('error' in response) {
      popupNotification('No data available', false);
    } else {
      setInvoiceDetails(response.data.invoiceDetails);
      const paymentDetails = {
        amountDue: response.data.amountDue,
        amountPaid: response.data.amountPaid,
      };
      setPaymentInfo(paymentDetails);
      setRows(response.data.invoiceDetails.products);
      setIsPaid(response.data.paid);
    }
  };
  useEffect(() => {
    getCompanyDetailsForInvoiceCreation(companyId, setData, setIsFetching);
    getInvoice();
  }, []);
  const saveInvoice = async () => {
    setIsSaved(true);
    setLoading(true);
    const payload = {
      httpMethod: 'POST',
      actionName: 'CreateCustomerInvoice',
      customerId: invoiceDetails?.customer?.customerId,
      // customerId: '3b21757e-45a2-464f-ba67-9079e39d3b49',
      invoiceDetails: {
        ...invoiceDetails,
        dueDate: invoiceDetails.dueDate?.format('YYYY-MM-DD'),
        invoiceDate: invoiceDetails.invoiceDate?.format('YYYY-MM-DD'),
        products: rows,
      },
    };
    const response = await universalPostRequest(
      APIEndpoint.secDocsMain,
      payload
    );
    setLoading(false);
    if ('error' in response) {
      popupNotification(response.error, false);
    } else {
      popupNotification(response.message, true);
    }
    reset();
  };
  const isSaveButtonActive = () => {
    return !invoiceDetails.customer || isSaved;
  };
  const handleTotalsUpdate = (
    subTotal: number,
    totalTax: number,
    totalAmount: number,
    amountAfterDiscount: number
  ) => {
    setInvoiceDetails((prev) => ({
      ...prev,
      subTotal,
      totalAmount,
      totalTax,
      amountAfterDiscount,
    }));
  };
  return (
    <>
      <OverlayLoader loading={isFetching} text="Please wait..." />
      {/* <HeaderComp>Invoice details</HeaderComp> */}
      <Box
        border="1px solid blue"
        display="flex"
        width={20}
        borderRadius="50%"
        height={20}
        alignItems="center"
        justifyContent="center"
      >
        <CustomIconButton
          tooltipTitle="Go back"
          buttonType="primary"
          onClick={() => setInvoiceId('')}
        >
          <ArrowBackIcon fontSize="small" />
        </CustomIconButton>
      </Box>

      <Box mt={4}>
        <Box display="flex" gap={2}>
          <Typography variant="body1" fontWeight="bold">
            Invoice No :
          </Typography>
          <Typography variant="body1">{invoiceDetails.invoiceNo}</Typography>
        </Box>
        <Box display="flex" gap={2}>
          <Typography variant="body1" fontWeight="bold">
            Customer name :
          </Typography>
          <Typography variant="body1">
            {invoiceDetails?.customer?.name}
          </Typography>
        </Box>
      </Box>
      <Box textAlign="right" mr={5}>
        {!isPaid && (
          <CustomIconButton
            tooltipTitle="Edit invoice"
            buttonType="primary"
            onClick={() => setIsSaved((prev) => !prev)}
          >
            <Edit color="warning" />
          </CustomIconButton>
        )}
        {!isPaid && (
          <CustomIconButton
            tooltipTitle="Update invoice"
            buttonType="primary"
            disabled={isSaveButtonActive()}
            onClick={saveInvoice}
          >
            <Save />
          </CustomIconButton>
        )}
        <CustomIconButton
          tooltipTitle="Download invoice"
          buttonType="primary"
          onClick={() => saveComponentAsPDF(invoiceRef)}
        >
          <SimCardDownloadIcon color="success" />
        </CustomIconButton>
      </Box>
      <Box ref={invoiceRef} p={0} height="100%">
        <Grid
          container
          p={2}
          border="1px solid lightgrey"
          borderRadius={8}
          height="100%"
        >
          <Grid item xs={12} pl={2} container>
            <Grid item xs={10}>
              <Typography variant="h6" fontWeight="bold">
                {header}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              {isPaid ? (
                <Typography variant="h5">PAID</Typography>
              ) : (
                <FormControlLabel
                  value="bottom"
                  control={
                    <Checkbox
                      checked={isPaid}
                      color="success"
                      size="small"
                      onChange={(e) => {
                        setIsPaid(e.target.checked);
                        setShowModal(true);
                      }}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  }
                  label={
                    <Typography
                      variant="body2"
                      sx={{
                        color: 'green', // Change label color
                        fontWeight: '500', // Semi-bold label
                      }}
                    >
                      Mark as paid
                    </Typography>
                  }
                  labelPlacement="end"
                />
              )}
            </Grid>
          </Grid>
          <Grid item md={3} pl={2}>
            <CompanyDetailsComp
              {...{
                invoiceDetails,
                setInvoiceDetails,
                isSaved,
                setEditCompanyAddress,
                editCompanyAddress,
                companyData: data,
              }}
            />
          </Grid>
          <Grid
            container
            item
            xs={12}
            borderRadius={4}
            bgcolor={grey[100]}
            pb={4}
            pt={4}
          >
            <Grid container item xs={12} pl={2} spacing={2}>
              <Grid item xs={4}>
                <CustomerDetailsComp
                  {...{ invoiceDetails, setInvoiceDetails, isSaved }}
                />
              </Grid>
              <Grid container item xs={12}>
                <Grid item xs={5}>
                  <Box
                    border="1px solid #ccc"
                    overflow="auto"
                    padding="8px"
                    minHeight="70px"
                    bgcolor="#f9f9f9"
                    borderRadius="8px"
                  >
                    {invoiceDetails.customer?.address
                      .split(',')
                      .map((addressBits) => (
                        <Typography variant="body2" key={addressBits}>
                          {addressBits}
                        </Typography>
                      )) || ''}
                  </Box>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid container item xs={6} alignItems="center">
                  <Grid item xs={3}>
                    <Typography variant="body2">Invoice no.</Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography variant="body2">
                      {invoiceDetails.invoiceNo}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}></Grid>
                  <Grid item xs={3}>
                    <Typography variant="body2">P.O. number</Typography>
                  </Grid>
                  <Grid item xs={5}>
                    {isSaved ? (
                      <Typography>{invoiceDetails.poNumber}</Typography>
                    ) : (
                      <TextField
                        value={invoiceDetails.poNumber}
                        onChange={(e) =>
                          setInvoiceDetails((prev) => ({
                            ...prev,
                            poNumber: e.target.value,
                          }))
                        }
                        size="small"
                        variant="outlined"
                      />
                    )}
                  </Grid>
                  <Grid item xs={4}></Grid>
                  <Grid item xs={3}>
                    <Typography variant="body2">Invoice date</Typography>
                  </Grid>
                  <Grid item xs={5}>
                    {isSaved ? (
                      <Typography variant="body2">
                        {dayjs(invoiceDetails.invoiceDate)
                          ?.toDate()
                          .toLocaleDateString()}
                      </Typography>
                    ) : (
                      <SmallDateComp
                        value={dayjs(invoiceDetails?.invoiceDate)}
                        onChange={(newValue) =>
                          setInvoiceDetails((prev) => ({
                            ...prev,
                            invoiceDate: dayjs(newValue),
                          }))
                        }
                      />
                    )}
                  </Grid>
                  <Grid item xs={4}></Grid>
                  <Grid item xs={3}>
                    <Typography variant="body2">Payment terms</Typography>
                  </Grid>
                  <Grid item xs={5}>
                    {isSaved ? (
                      <Typography variant="body2">
                        {invoiceDetails?.paymentTerms}
                      </Typography>
                    ) : (
                      <Select
                        value={invoiceDetails?.paymentTerms}
                        onChange={(e: SelectChangeEvent<string>) =>
                          setInvoiceDetails((prev) => ({
                            ...prev,
                            paymentTerms: e.target.value,
                          }))
                        }
                        displayEmpty
                      >
                        <MenuItem value="" disabled>
                          Select payment terms
                        </MenuItem>
                        {data?.paymentTerms?.map((term) => (
                          <MenuItem key={term} value={term}>
                            {term}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  </Grid>
                  <Grid item xs={4}></Grid>
                  <Grid item xs={3}>
                    <Typography variant="body2">Due date</Typography>
                  </Grid>
                  <Grid item xs={5}>
                    {isSaved ? (
                      <Typography variant="body2">
                        {dayjs(invoiceDetails.dueDate)
                          ?.toDate()
                          .toLocaleDateString()}
                      </Typography>
                    ) : (
                      <SmallDateComp
                        value={dayjs(invoiceDetails.dueDate)}
                        onChange={(newValue) =>
                          setInvoiceDetails((prev) => ({
                            ...prev,
                            dueDate: dayjs(newValue),
                          }))
                        }
                      />
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <ProductTable
              onTotalsUpdate={handleTotalsUpdate}
              discountAmount={invoiceDetails.discountAmount}
              isSaved={isSaved}
              setRows={setRows}
              rows={rows && rows.length ? rows : []}
              discountPercentage={invoiceDetails.discountPercentage || 0}
              flatDiscount={invoiceDetails.flatDiscount}
            />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid container item xs={12} mt={5}>
            <Grid container spacing={4} item xs={9}>
              <Grid item xs={6}>
                <PaymentDetailsComp
                  invoiceDetails={invoiceDetails}
                  setInvoiceDetails={setInvoiceDetails}
                  isSaved={isSaved}
                  companyData={data}
                  companyId={companyId}
                />
              </Grid>
              <Grid item xs={6}></Grid>
              <Grid item xs={6}>
                <Typography variant="body2">Note to customer</Typography>
              </Grid>
              <Grid item xs={6}></Grid>
              <Grid item xs={6}>
                <TextField
                  multiline
                  disabled
                  rows={3}
                  value={invoiceDetails.notes}
                  variant="outlined"
                  placeholder="Tell your customer how you want to get paid"
                  fullWidth
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '10px',
                      backgroundColor: '#f9f9f9',
                      '&:hover fieldset': {
                        borderColor: '#3bd6c6',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#3bd6c6',
                        borderWidth: '2px',
                      },
                    },
                    '& .MuiInputLabel-root': {
                      color: '#666',
                    },
                    '& .MuiInputLabel-root.Mui-focused': {
                      color: '#3bd6c6',
                    },
                  }}
                />
              </Grid>
            </Grid>
            <Grid container item xs={3} spacing={0}>
              <Grid item xs={8}>
                <Typography variant="body1">Sub total</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography align="right" variant="body1">
                  {formatAmount(invoiceDetails?.subTotal)}
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body1">Discount(%)</Typography>
              </Grid>
              <Grid item xs={4}>
                {isSaved ? (
                  <Typography variant="body1" align="right">
                    {invoiceDetails.discountPercentage}%
                  </Typography>
                ) : (
                  <TextField
                    type="number"
                    size="small"
                    disabled
                    inputProps={{
                      inputMode: 'numeric',
                      pattern: '[0-9]*',
                    }}
                    onChange={(
                      e: React.ChangeEvent<
                        HTMLInputElement | HTMLTextAreaElement
                      >
                    ) =>
                      setInvoiceDetails((prev) => ({
                        ...prev,
                        discountPercentage: Number(e.target.value),
                      }))
                    }
                  />
                )}
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body1">Amount after discount</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography align="right" variant="body1">
                  {formatAmount(invoiceDetails?.amountAfterDiscount)}
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body1">Total tax</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography align="right" variant="body1">
                  {formatAmount(invoiceDetails?.totalTax)}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body1" fontWeight="bold">
                  Invoice total
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography align="right" variant="body1" fontWeight="bold">
                  {formatAmount(invoiceDetails?.totalAmount)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} mt={4}>
            <Typography variant="body2" fontWeight="bold">
              Terms and conditions
            </Typography>
            <Typography variant="subtitle2" fontWeight="thin">
              {data.termsAndConditions}
            </Typography>
          </Grid>
          <Grid item xs={12} mt={4}>
            <Divider />
          </Grid>
          <Grid item xs={12} textAlign="center" mt={4}>
            <Footer />
          </Grid>
        </Grid>
      </Box>
      {/* <CustomModal open={showModal} onClose={()=>setShowModal(false)}
        primaryButtonAction={}
        >

      </CustomModal> */}
      <CustomModal
        primaryButtonAction={handleSubmit(changePaymentStatus)}
        primaryButtonLabel="Submit"
        autoClose={true}
        open={showModal}
        type="info"
        onClose={() => {
          setShowModal(false);
        }}
        title="Mark as paid"
      >
        <form onSubmit={handleSubmit(changePaymentStatus)}>
          <Typography variant="body2" color="textDisabled" gutterBottom>
            Please provide the closing comments and amount
          </Typography>

          {/* Closing Comments */}
          <InputElement
            name="closingComments"
            size="small"
            control={control}
            label="Closing Comments"
            type="text"
            required
            placeholder="Closing comments"
            multiline
            autoFocus
            rows={3}
            rules={{ required: 'Closing comments are required' }}
          />

          {/* Amount */}
          <InputElement
            name="amount"
            size="small"
            control={control}
            required
            label="Enter amount paid"
            placeholder="Enter amount paid"
            type="number"
            rules={{
              required: 'Amount is required',
              min: { value: 1, message: 'Amount must be greater than 0' },
            }}
          />
        </form>
      </CustomModal>
    </>
  );
};

export default UpdateInvoicePage;
