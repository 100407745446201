import React, { useState } from 'react';

import { ExpandLess, ExpandMore } from '@mui/icons-material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import DraftsIcon from '@mui/icons-material/Drafts';
import GroupIcon from '@mui/icons-material/Group';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import HomeIcon from '@mui/icons-material/Home';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import ReceiptIcon from '@mui/icons-material/Receipt';
import SettingsIcon from '@mui/icons-material/Settings';
import SnippetFolderIcon from '@mui/icons-material/SnippetFolder';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import { Box, Collapse, Drawer, IconButton, List } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';

import getMenu from '../config/navigation'; // Import the JSON file
import useNavigateWithUUID from '../hooks/useNavigateWithUUID';
import NavListItem from './SidebarNavList';
interface SidebarProps {
  isCollapsed: boolean;
  onToggleCollapse: () => void;
}

const iconMapping: any = {
  HomeIcon: <HomeIcon />,
  PersonAddAlt1Icon: <PersonAddAlt1Icon />,
  SettingsIcon: <SettingsIcon />,
  GroupAddIcon: <GroupAddIcon />,
  Mappings: <Diversity3Icon />,
  Menu: <SnippetFolderIcon />,
  InviteUser: <SupervisedUserCircleIcon />,
  Contract: <DraftsIcon />,
  Invoice: <ReceiptIcon />,
  Users: <GroupIcon />,
};

export const Sidebar: React.FC<SidebarProps> = ({
  isCollapsed,
  onToggleCollapse,
}) => {
  const { role } = useSelector((state: RootState) => state.auth);
  const { generateUrlWithUuid } = useNavigateWithUUID();
  const [openMenus, setOpenMenus] = useState<{ [key: string]: boolean }>({});
  const menu = getMenu(role);
  const handleMenuToggle = (path: string) => {
    setOpenMenus((prev) => ({
      ...prev,
      [path]: !prev[path],
    }));
  };

  const renderNavItem = (item: any) => {
    const hasChildren = item.children && item.children.length > 0;

    return (
      <div key={item.path}>
        <NavListItem
          icon={iconMapping[item.icon]}
          label={item.label}
          path={item.path}
          generateUrlWithUuid={generateUrlWithUuid}
          isCollapsed={isCollapsed}
          onClick={hasChildren ? () => handleMenuToggle(item.path) : undefined}
          endIcon={
            hasChildren &&
            (openMenus[item.path] ? <ExpandLess /> : <ExpandMore />)
          }
        />
        {hasChildren && !isCollapsed && (
          <Collapse in={openMenus[item.path]} timeout="auto" unmountOnExit>
            {item.children.map((child: any) => (
              <NavListItem
                key={child.path}
                icon={iconMapping[child.icon]} // Reuse parent icon for nested items
                label={child.label}
                path={child.path}
                generateUrlWithUuid={generateUrlWithUuid}
                sx={{ pl: 4 }} // Indent nested items
                isCollapsed={isCollapsed}
              />
            ))}
          </Collapse>
        )}
      </div>
    );
  };

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: isCollapsed ? 20 : 240,
        position: 'relative',
        '& .MuiDrawer-paper': {
          width: isCollapsed ? 60 : 240,
          backgroundColor: '#f8f9fa',
          border: 'none',
          boxSizing: 'border-box',
          height: 'calc(100vh - 64px)',
          top: 64,
          transition: 'width 0.3s ease',
        },
        transition: 'width 0.3s ease',
      }}
    >
      <List sx={{ pt: 3 }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: isCollapsed ? 'center' : 'flex-end',
            pr: isCollapsed ? 0 : 2,
          }}
        >
          <IconButton
            onClick={onToggleCollapse}
            sx={{
              transition: 'transform .5s ease',
              transform: isCollapsed ? 'rotate(180deg)' : 'rotate(0deg)',
            }}
          >
            <ChevronLeftIcon />
          </IconButton>
        </Box>

        {menu.map((item) => renderNavItem(item))}
      </List>
    </Drawer>
  );
};

export default Sidebar;
