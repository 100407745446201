import React, { useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import AppleIcon from '@mui/icons-material/Apple';
import {
  Box,
  Button,
  Grid,
  Hidden,
  IconButton,
  InputAdornment,
  Link as MUILink,
  Typography,
} from '@mui/material';
import { APIEndpoint } from 'common/enum';
import { LoginResponse } from 'common/interface';
import { universalPostRequest } from 'common/requestHandler';
import { logoutUserOtherSessions } from 'common/utils';
import { popupNotification } from 'notifications/ToastNotification';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { validateUsernameOrEmail } from 'screens/superAdmin/validation';
import colors from 'theme/colors';
import * as yup from 'yup';

import GooglePlayIcon from '../../assets/icons/google.png';
import docImage from '../../assets/images/login2.svg';
import logo from '../../assets/images/logo.png';
import CustomModal from '../../components/CustomModal';
import GradientButton from '../../components/elements/GradientButton';
import InputElement from '../../components/elements/InputElement';
import { login, logout } from '../../redux/slices/authSlice';

interface LoginFormInputs {
  username: string;
  password: string;
}

const schema = yup.object().shape({
  username: validateUsernameOrEmail,
  password: yup.string().required('Password is required'),
});

const LoginPage: React.FC = () => {
  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<LoginFormInputs>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      username: '',
      password: '',
    },
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showAlreadyLoggedInModal, setShowAlreadyLoggedInModal] =
    useState(false);
  const [isFirstLogIn, setIsFirstLogin] = useState(false);
  const [loggingOutUser, setLoggingOutUser] = useState(false);
  const [username, setUsername] = useState('');

  const onSubmit: SubmitHandler<LoginFormInputs> = async (data) => {
    setLoading(true);
    const response = await universalPostRequest<LoginResponse>(
      APIEndpoint.login,
      {
        username: data.username,
        password: data.password,
      }
    );
    setLoading(false);
    if ('error' in response) {
      popupNotification(response.error, false);
    } else {
      dispatch(
        login({
          accessToken: response.data.access_token,
          refreshToken: response.data.refresh_token,
          idToken: response.data.id_token,
          expiresIn: response.data.expires_in,
          date: response.data.date,
          deviceKey: response.data.device_key,
          secretCode: response.data.secret_code,
          username: response.data.username,
          isFirstLogin: response.data.is_first_login,
          isMfaActive: response.data.is_mfa_active,
          sessionKey: response.data.session_key,
        })
      );
      setIsFirstLogin(response.data.is_user_loggedin);
      if (response.data.is_user_loggedin) {
        setShowAlreadyLoggedInModal(true);
        setUsername(response.data.username);
        return;
      }
      if (response.data.is_first_login) {
        navigate('/mfa-config');
      } else {
        navigate('/verify-mfa');
      }
    }
  };

  const handleRedirection = async () => {
    setLoggingOutUser(true);
    await logoutUserOtherSessions(username, setLoggingOutUser);
    setLoggingOutUser(false);
    setShowAlreadyLoggedInModal(false);
    const values = getValues();
    onSubmit(values);
  };

  return (
    <>
      <Grid container style={{ height: '100vh', backgroundColor: '#F9F9F9' }}>
        <Grid
          item
          xs={12}
          md={6}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <Box display="flex" justifyContent="center">
            <img src={logo} alt="Logo" height={100} />
          </Box>
          <Box sx={{ width: '80%', padding: '20px' }}>
            <Typography
              variant="h4"
              fontWeight="bold"
              component="h1"
              gutterBottom
              fontFamily="Poppins"
            >
              Sign in
            </Typography>
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
              <InputElement
                name="username"
                control={control}
                required
                label="Username or Email"
                type="text"
                autoFocus
                rules={{ required: true }}
              />
              <InputElement
                name="password"
                control={control}
                label="Password"
                type={showPassword ? 'text' : 'password'}
                rules={{ required: true }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword((prev) => !prev)}
                        edge="end"
                        size="small"
                      >
                        {showPassword ? (
                          <VisibilityOff fontSize="small" />
                        ) : (
                          <Visibility fontSize="small" />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Box mt={2}>
                <GradientButton fullWidth type="submit" loading={loading}>
                  Sign in
                </GradientButton>
              </Box>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="flex-start"
                mt={2}
              >
                <Button
                  onClick={() => setShowModal(true)}
                  style={{ color: colors.primary, textDecoration: 'none' }}
                >
                  Need help?
                </Button>
                <Box display="flex" flexDirection="column" gap={1}>
                  <Link
                    to="/forgot-username"
                    style={{ color: colors.primary, textDecoration: 'none' }}
                  >
                    Forgot username
                  </Link>
                  <Link
                    to="/forgot-password"
                    style={{ color: colors.primary, textDecoration: 'none' }}
                  >
                    Forgot password
                  </Link>
                </Box>
              </Box>
            </form>
          </Box>
        </Grid>
        <Hidden smDown>
          <Grid
            item
            xs={12}
            md={6}
            style={{
              display: 'flex',
              flexDirection: 'column',
              backgroundColor: 'whitesmoke',
            }}
          >
            <Typography
              variant="h6"
              fontWeight="bold"
              align="center"
              mt={10}
              sx={{
                background: 'linear-gradient(45deg, #42d392, #647eff)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                fontWeight: 'bold',
                fontSize: '2rem',
              }}
            >
              Share Documents Securely
            </Typography>
            <Typography
              variant="body1"
              fontWeight="bold"
              textAlign="center"
              color="white"
              sx={{
                background: 'linear-gradient(45deg, #42d392, #647eff)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                fontWeight: 'bold',
              }}
            >
              Seamless sharing with top security
            </Typography>
            <img src={docImage} alt="" />
          </Grid>
        </Hidden>
        <CustomModal
          type="info"
          open={showModal}
          onClose={() => setShowModal(false)}
          primaryButtonAction={() => setShowModal(false)}
          primaryButtonLabel="Ok"
          title="Help"
        >
          <Typography variant="subtitle1" color="textDisabled">
            Before logging in for the first time, download a software token
            authenticator on your mobile phone.
          </Typography>
          <ul>
            <li>
              Get the authenticator app from the{' '}
              <MUILink
                href="https://play.google.com/store/search?q=Authenticator&c=apps"
                rel="noreferrer"
                target="_blank"
                sx={{ textDecoration: 'none' }}
              >
                Play store
                <img
                  src={GooglePlayIcon}
                  alt=""
                  width={20}
                  style={{ marginLeft: 5 }}
                />
              </MUILink>{' '}
              or{' '}
              <MUILink
                href="https://apps.apple.com/search?term=Authenticator"
                rel="noreferrer"
                target="_blank"
                sx={{ textDecoration: 'none' }}
              >
                App Store
                <AppleIcon />
              </MUILink>
            </li>
            <li>
              In the app select{' '}
              <span style={{ fontWeight: 600 }}>Set up account</span>
            </li>
            <li>
              Choose <span style={{ fontWeight: 600 }}>Scan barcode</span>
            </li>
          </ul>
        </CustomModal>
        <CustomModal
          type="error"
          open={showAlreadyLoggedInModal}
          onClose={() => setShowAlreadyLoggedInModal(false)}
          autoClose={false}
          primaryButtonAction={handleRedirection}
          primaryButtonLabel="Logout other session"
          secondaryButtonLabel="Continue other session"
          primaryButtonLoading={loggingOutUser}
          secondaryButtonAction={() => dispatch(logout())}
          title="Already logged in"
        >
          <Typography variant="body2">
            You are already logged in on another session or your previous
            session was not properly terminated. Would you like to log out of
            the other session and continue here?
          </Typography>
        </CustomModal>
      </Grid>
    </>
  );
};

export default LoginPage;
