import { APIEndpoint } from 'common/enum';
import { previousFileVersionInterface } from 'common/interface';
import { universalGetRequest } from 'common/requestHandler';
import {
    FileData,
    getUserFilesAndFolders,
    getUsers,
    parseFilesAndFolders,
} from 'common/utils';
import { popupNotification } from 'notifications/ToastNotification';
import { createFolder } from 'screens/dashboard/utils';

interface loadingStates {
  fetchingFiles: boolean;
  creatingFolder: boolean;
  loadingUsers: boolean;
  fetchingFileVersion: boolean;
}

export const getUserFiles = async (
  selectedUserId: string | null,
  setLoading: (value: React.SetStateAction<loadingStates>) => void,
  uuid: string | undefined,
  setUserFiles: (value: React.SetStateAction<FileData[]>) => void
) => {
  setLoading((prev) => ({ ...prev, fetchingFiles: true }));

  const response = await getUserFilesAndFolders(uuid || '', selectedUserId);
  if ('error' in response) {
    popupNotification('Something went wrong', false);
  } else {
    const parsedData: FileData[] = parseFilesAndFolders(response.data);
    setUserFiles(parsedData);
  }

  setLoading((prev) => ({ ...prev, fetchingFiles: false }));
};

export const getAllFileVersions = async (
  file: FileData,
  setSelectFileForVersion: (
    value: React.SetStateAction<FileData | undefined>
  ) => void,
  setShowFileVersionModal: (value: React.SetStateAction<boolean>) => void,
  setLoading: (value: React.SetStateAction<loadingStates>) => void,
  setPreviousFileVersions: (
    value: React.SetStateAction<previousFileVersionInterface[]>
  ) => void
) => {
  setSelectFileForVersion(file);
  setShowFileVersionModal(true);
  setLoading((prev) => ({ ...prev, fetchingFileVersion: true }));
  const response = await universalGetRequest(APIEndpoint.getFileVersions, {
    objectKey: `${file['file-path']}`,
  });
  if ('error' in response) {
    popupNotification(response.error, false);
  } else {
    const data: any = response.data;
    setPreviousFileVersions(data);
  }
  setLoading((prev) => ({ ...prev, fetchingFileVersion: false }));
};

export const handleCreateNewFolder = async ({
  newFolderName,
  setLoading,
  isPrivate,
  uuid,
  selectedOption,
  baseFolder,
  newFolderPath,
  popupNotification,
  getUserFiles,
  setUserFiles,
  setNewFolderName,
  setShowModal,
  setIsPrivate,
  setPath,
  isCorporate,
}: {
  newFolderName: string;
  setLoading: React.Dispatch<React.SetStateAction<any>>;
  isPrivate: boolean;
  uuid: string;
  selectedOption: string | null;
  baseFolder: string;
  newFolderPath: string;
  popupNotification: (message: string, success: boolean) => void;
  getUserFiles: (
    option: string | null,
    setLoading: any,
    uuid: string,
    setUserFiles: any
  ) => Promise<void>;
  setUserFiles: React.Dispatch<React.SetStateAction<any>>;
  setNewFolderName: React.Dispatch<React.SetStateAction<string>>;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  setIsPrivate: React.Dispatch<React.SetStateAction<boolean>>;
  setPath: React.Dispatch<React.SetStateAction<string[]>>;
  isCorporate: boolean;
}) => {
  if (!newFolderName) return;

  setLoading((prev: any) => ({ ...prev, creatingFolder: true }));

  const companyPrivateFolderParameter = `${uuid}/${baseFolder}/${newFolderPath}`;
  const indFolderParameter = `${uuid}/${selectedOption}/${baseFolder}/${newFolderPath}`;
  const useParameter =
    isPrivate && !isCorporate
      ? companyPrivateFolderParameter
      : indFolderParameter;

  const response = await createFolder(useParameter, newFolderName);

  if ('error' in response) {
    popupNotification(response.error, false);
  } else {
    popupNotification('Successfully created folder', true);
  }

  setNewFolderName('');

  if (isPrivate) {
    await getPrivateSharedFiles(
      setLoading,
      setIsPrivate,
      setUserFiles,
      setPath,
      isCorporate
    );
  } else {
    await getUserFiles(selectedOption, setLoading, uuid, setUserFiles);
  }

  setShowModal(false);
  setLoading((prev: any) => ({ ...prev, fetchingFiles: false }));
};

export const getUsersByAction = async (
  actionName: string,
  setLoading: (value: React.SetStateAction<loadingStates>) => void,
  uuid: string | undefined,
  employeeId: string | null,
  setUsers: (value: React.SetStateAction<any[]>) => void
) => {
  setLoading((prev) => ({ ...prev, loadingUsers: true }));
  const response = await getUsers(
    uuid,
    actionName,
    employeeId,
    'ForFolderFiles'
  );
  if ('error' in response) {
    popupNotification(response.error, false);
  } else {
    setUsers(response.data);
  }
  setLoading((prev) => ({ ...prev, loadingUsers: false }));
};

export const getPrivateSharedFiles = async (
  setLoading: (value: React.SetStateAction<loadingStates>) => void,
  setIsPrivate: (value: React.SetStateAction<boolean>) => void,
  setUserFiles: (value: React.SetStateAction<FileData[]>) => void,
  setPath: (value: React.SetStateAction<string[]>) => void,
  isCorporate: boolean
) => {
  setLoading((prev) => ({ ...prev, fetchingFiles: true }));
  setIsPrivate(true);
  setUserFiles([]);
  setPath([]);
  const response: any = await universalGetRequest(APIEndpoint.getSecDocsMain, {
    actionName: isCorporate ? 'XV3RTD' : 'XV1WAL',
    httpMethod: 'GET',
  });
  if ('error' in response) {
    popupNotification('Something went wrong', false);
  } else {
    const parsedData: FileData[] = parseFilesAndFolders(response.data);
    setUserFiles(parsedData);
  }
  setLoading((prev) => ({ ...prev, fetchingFiles: false }));
};
