import { userTypes } from 'config/config';
import PageNotFound from 'pages/404';
import UnauthorizedPage from 'pages/UnauthorizedPage';
import { Route, Routes } from 'react-router-dom';
import AccessControl from 'screens/accessControl/AccessControl';
import CreateCustomerGroup from 'screens/accessControl/CustomerGroup';
import Mappings from 'screens/accessControl/Mapping';
import StaffGroups from 'screens/accessControl/StaffGroups';
import AdminDashboard from 'screens/admin/AdminDashboard';
import Invite from 'screens/admin/invite/Invite';
import InviteCorporate from 'screens/admin/invite/InviteCorporate';
import InviteCustomer from 'screens/admin/invite/InviteCustomer';
import InviteStaffUser from 'screens/admin/invite/InviteStaffuser';
import AnalyticsScreen from 'screens/analytics/Analytics';
import ContractManagement from 'screens/contractManagement/ContractManagement';
import InvoiceManagement from 'screens/invoice/InvoiceManagement';
// import InvoiceCustomerManagement from 'screens/invoice/quick/templates/InvoiceCustomersMgmt';
// import InvoiceManagement from 'screens/invoice/quick/templates/InvoiceManagement';
// import EditTemplate from 'screens/invoice/templates/EditTemplate';
import ChangePassword from 'screens/password/ChangePassword';
import Preference from 'screens/preferences/Preference';
import UserManagement from 'screens/userManagement/UserManagement';

import Layout from '../components/Layout';
import ProtectedRoute from './ProtectedRoute';

const CompanyAdminRoutes = () => (
  <ProtectedRoute allowedRoles={[userTypes.companyAdmin]}>
    <Layout>
      <Routes>
        <Route path="dashboard" element={<AnalyticsScreen />} />
        <Route path="documents/*" element={<AdminDashboard />} />
        <Route path="invite-user" element={<Invite />} />
        <Route
          path="invite-user/invite-customer"
          element={<InviteCustomer />}
        />
        <Route path="invite-user/invite-staff" element={<InviteStaffUser />} />
        <Route
          path="invite-user/invite-corporate"
          element={<InviteCorporate />}
        />
        {/* <Route path="preference" element={<PreferenceAdmin />} /> */}
        <Route path="preference" element={<Preference />} />
        <Route path="access-control" element={<AccessControl />} />
        <Route path="access-control/staff-groups" element={<StaffGroups />} />
        <Route
          path="access-control/create-customer-group"
          element={<CreateCustomerGroup />}
        />
        <Route path="user-management" element={<UserManagement />} />
        <Route path="access-control/mappings" element={<Mappings />} />
        <Route path="change-password" element={<ChangePassword />} />
        <Route path="/unauthorized" element={<UnauthorizedPage />} />
        <Route path="/contract-management" element={<ContractManagement />} />
        {/* <Route path="/contract-management" element={<ContractManagement />} /> */}
        <Route path="/invoice-management" element={<InvoiceManagement />} />
        {/* <Route path="/invoice-management/templates" element={<Templates />} /> */}
        {/* <Route path="/invoice-management/templates" element={<InvoicePage />} /> */}
        {/* <Route
          path="/invoice-management/invoice/:invoiceId"
          element={<UpdateInvoiceTemplate />}
        /> */}
        {/* <Route
          path="/invoice-management/customers"
          element={<InvoiceCustomerManagement />}
        /> */}
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </Layout>
  </ProtectedRoute>
);
export default CompanyAdminRoutes;
