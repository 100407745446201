import { APIEndpoint } from 'common/enum';
import { addressFormInterface } from 'common/interface';
import {
  universalGetRequest,
  universalPostRequest,
} from 'common/requestHandler';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { popupNotification } from 'notifications/ToastNotification';

import { CompanyDataInterface, CustomerInterface } from './types';

export const formatKey = (key: string) => {
  return key
    .replace(/([A-Z])/g, ' $1') // Add space before each uppercase letter
    .replace(/^./, (str) => str.toUpperCase()) // Capitalize the first letter
    .trim(); // Remove any leading/trailing spaces
};
export const calculateDiscountAmount = (
  discountPercentage: number,
  subtotal: number
): number => {
  // Calculate discount amount
  const discountAmount = (discountPercentage / 100) * subtotal;
  return Number(discountAmount.toFixed(2));
};

export const saveComponentAsPDF = async (
  componentRef: React.RefObject<HTMLDivElement>, // Ref type for HTMLDivElement
  fileName: string = 'document.pdf',
  margin: number = 20
): Promise<void> => {
  if (!componentRef.current) {
    console.error('Component reference is invalid.');
    return;
  }

  try {
    // Capture the component as an image using html2canvas
    const canvas = await html2canvas(componentRef.current, {
      scale: 2, // Increase scale for better quality
      useCORS: true, // Allow cross-origin images
    });

    const imgData = canvas.toDataURL('image/jpeg', 0.8); // Compress image to reduce size
    const pdf = new jsPDF({
      orientation: 'portrait',
      unit: 'pt',
      format: 'a4',
    });

    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();
    const imgWidth = canvas.width;
    const imgHeight = canvas.height;
    const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);

    const imgX = (pdfWidth - imgWidth * ratio) / 2; // Center the content
    const imgY = 0;

    // Add the image to the PDF with adjusted dimensions
    pdf.addImage(
      imgData,
      'JPEG',
      imgX,
      imgY,
      imgWidth * ratio,
      imgHeight * ratio
    );
    pdf.text('This is a footer text.', margin, pdfHeight - margin);
    pdf.save(fileName);
  } catch (error) {
    console.error('Error saving PDF:', error);
  }
};
export const saveTemplate = async (data: any) => {
  const response = await universalPostRequest(APIEndpoint.secDocsMain, data);
  if ('error' in response) {
    popupNotification(response.error, false);
  } else {
    popupNotification(response.message, true);
  }
};
export function formatAddresses(companyList: addressFormInterface[]) {
  return companyList.map((address) => {
    const {
      address1 = '',
      address2 = '',
      address3 = '',
      city = '',
      state = '',
      postCode = '',
    } = address;

    // Concatenate and filter out empty strings
    return [address1, address2, address3, city, state, postCode]
      .filter((part) => part) // Remove any undefined or empty values
      .join(', '); // Join with commas
  });
}
export async function saveInvoiceCustomerDetails(data: any) {
  const response = await universalPostRequest(APIEndpoint.secDocsMain, data);
  if ('error' in response) {
    popupNotification(response.error, false);
  } else {
    popupNotification(response.message, true);
  }
}

const transformToCustomerInterface = (data: any): CustomerInterface[] => {
  return data.map((item: any) => {
    const {
      customerName,
      customerId,
      address1,
      address2,
      city,
      state,
      postCode,
    } = item;

    // Combine address parts, filtering out undefined/null
    const address = [address1, address2, city, state, postCode]
      .filter(Boolean)
      .join(', ');

    return {
      name: customerName,
      customerId: customerId, // Assuming GST acts as an optional ID
      address,
    };
  });
};
export async function getCustomersForInvoice(
  setInvoiceDetails: React.Dispatch<React.SetStateAction<CustomerInterface[]>>
) {
  const response = await universalGetRequest(APIEndpoint.getSecDocsMain, {
    httpMethod: 'GET',
    actionName: 'GetCustomerInvoiceAddress',
  });
  if ('error' in response) {
    popupNotification('Cannot get customer', false);
  } else {
    if (Array.isArray(response.data)) {
      const customers: CustomerInterface[] = transformToCustomerInterface(
        response.data
      );
      setInvoiceDetails(customers);
    }
  }
}
export const transformToCompanyAddressInterface = (data: any) => {
  const { companyName, address1, address2, city, state, postCode } = data;

  // Combine address parts, filtering out undefined/null
  const address = [address1, address2, city, state, postCode]
    .filter(Boolean)
    .join(', ');

  return companyName + ' , ' + address;
};
export const getCompanyDetailsForInvoiceCreation = async (
  companyId: string | null,
  setCompanyDetails: React.Dispatch<React.SetStateAction<CompanyDataInterface>>,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
) => {
  // if (!companyId) return;
  const response: any = await universalGetRequest(APIEndpoint.getSecDocsMain, {
    companyId,
    httpMethod: 'GET',
    actionName: 'GetInvoiceTemplate',
  });
  setLoading(false);
  if ('error' in response) {
    popupNotification('Something went wrong, try later', false);
  } else {
    setCompanyDetails(response.data);
  }
};
export function formatAmount(amount: number) {
  return parseFloat(amount?.toString())
    ?.toFixed(2) // Ensure two decimal places
    ?.replace(/\B(?=(\d{3})+(?!\d))/g, ','); // Add commas
}
