import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Delete } from '@mui/icons-material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Box, Grid, MenuItem, Paper, Select, Typography } from '@mui/material';
import CustomIconButton from 'components/elements/IconButton';
import InputElement from 'components/elements/InputElement';
import { useDrag, useDrop } from 'react-dnd';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { getRandomLightColor } from '../utils';

const signeeType = ['Realtor', 'Lawyer', 'Tenant', 'Landlord'];

const validationSchema = yup.object().shape({
  fields: yup
    .array()
    .of(
      yup.object().shape({
        name: yup
          .string()
          .required('Name is required')
          .max(15, 'Max length is 15 characters'),
        email: yup
          .string()
          .email('Invalid email')
          .required('Email is required'),
        signeeType: yup.string().required('Signee type is required'),
      })
    )
    .required(),
});

interface FormValues {
  fields: { name: string; email: string; signeeType: string }[];
}

const ItemType = 'ROW';

const DraggableRow = ({ item, index, moveItem, remove, control }: any) => {
  const [bgColor, setBgColor] = useState<string>('');

  useEffect(() => {
    setBgColor(getRandomLightColor());
  }, []);

  // Drag source
  const [{ isDragging }, drag] = useDrag({
    type: ItemType,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  // Drop target
  const [, drop] = useDrop({
    accept: ItemType,
    hover: (draggedItem: any) => {
      if (draggedItem.index !== index) {
        moveItem(draggedItem.index, index);
        draggedItem.index = index;
      }
    },
  });

  return (
    <Paper
      ref={(node) => drag(drop(node))}
      style={{
        padding: '10px',
        marginBottom: '10px',
        backgroundColor: isDragging ? 'lightgray' : 'rgb(252, 252, 252)',
        cursor: 'grab',
        opacity: isDragging ? 0.5 : 1,
      }}
    >
      <Grid container spacing={2} alignItems="center" justifyContent="center">
        <Grid item xs={1}>
          <Box
            display="flex"
            height={24}
            alignItems="center"
            justifyContent="center"
            width={24}
            sx={{ borderRadius: '50%', opacity: 0.6 }}
            bgcolor={bgColor}
          >
            <Typography variant="subtitle2">{index + 1}</Typography>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <InputElement
            name={`fields.${index}.name`}
            control={control}
            label="Name"
            required
          />
        </Grid>
        <Grid item xs={3}>
          <InputElement
            name={`fields.${index}.email`}
            control={control}
            label="Email"
            required
          />
        </Grid>
        <Grid item xs={3}>
          {/* MUI Select for Signee Type */}
          <Controller
            name={`fields.${index}.signeeType`}
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                fullWidth
                displayEmpty
                variant="outlined"
                size="small"
              >
                <MenuItem value="" disabled>
                  Signer profile
                </MenuItem>
                {signeeType.map((type) => (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </Grid>
        <Grid item xs={1}>
          <CustomIconButton
            buttonType="error"
            tooltipTitle="Remove"
            onClick={() => remove(index)}
          >
            <Delete />
          </CustomIconButton>
        </Grid>
      </Grid>
    </Paper>
  );
};

// 👇 Forward ref to expose form methods to the parent
const DynamicForm = forwardRef(({ setContractDetails }: any, ref) => {
  const { control, handleSubmit, getValues, trigger, watch } =
    useForm<FormValues>({
      defaultValues: { fields: [{ name: '', email: '', signeeType: '' }] },
      resolver: yupResolver(validationSchema),
      mode: 'onChange',
    });

  const { fields, append, remove, move } = useFieldArray({
    control,
    name: 'fields',
  });

  // Watch form values and update parent component (contractDetails)
  useEffect(() => {
    const subscription = watch((values) => {
      setContractDetails((prev: any) => ({
        ...prev,
        dynamicFormValues: values.fields,
      }));
    });

    return () => subscription.unsubscribe();
  }, [watch, setContractDetails]);

  useImperativeHandle(ref, () => ({
    validateAndGetValues: async () => {
      const isValid = await trigger();
      if (isValid) return getValues();
      return null;
    },
  }));

  return (
    <form>
      {fields.map((item, index) => (
        <DraggableRow
          key={item.id}
          item={item}
          index={index}
          moveItem={move}
          remove={remove}
          control={control}
        />
      ))}
      <CustomIconButton
        buttonType="primary"
        tooltipTitle="Add more"
        onClick={() => append({ name: '', email: '', signeeType: '' })}
      >
        <AddCircleIcon />
      </CustomIconButton>
    </form>
  );
});

export default DynamicForm;
