import { useRef, useState } from 'react';

import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import {
  Box,
  Button,
  IconButton,
  Radio,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from '@mui/material';
import { green } from '@mui/material/colors';
import IndiaFlag from 'assets/icons/IN.svg';
import USAFlag from 'assets/icons/US.svg';
import logo from 'assets/images/logo.png';
import lottieAnimation from 'assets/lottie/Animation.json';
import { APIEndpoint } from 'common/enum';
import { universalPostRequest } from 'common/requestHandler';
import CustomCard from 'components/CustomCard';
import CustomModal from 'components/CustomModal';
import GradientButton from 'components/elements/GradientButton';
import useNavigateWithUUID from 'hooks/useNavigateWithUUID';
import Lottie from 'lottie-react';
import { popupNotification } from 'notifications/ToastNotification';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';

import QR from '../../../assets/images/QR.jpeg';
import SampleContract from '../SampleContract';

const SetupContractManagement = () => {
  const [showModal, setShowModal] = useState(false);
  const { navigateWithUuid } = useNavigateWithUUID();
  const { companyId, employeeId, username, corporateId, customerId } =
    useSelector((state: RootState) => state.auth);
  const contentRef = useRef<HTMLDivElement | null>(null);
  const [isContentReady, setIsContentReady] = useState(false);
  const [textFieldValue, setTextFieldValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(0);
  const [selectedCountry, setSelectedCountry] = useState('');
  const steps = ['Agreement', 'Country selection', 'Payment', 'Complete'];

  const saveConsent = async () => {
    setLoading(true);
    const payload = {
      companyId,
      username,
      iAgreeClicked: true,
      agreementVersionNo: 1,
      actionName: 'SignupForSignVerify',
      httpMethod: 'POST',
      customerId: customerId ?? employeeId,
    };
    const response = await universalPostRequest(
      APIEndpoint.SignUpForDocSign,
      payload
    );
    setLoading(false);
    if ('error' in response) {
      popupNotification(response.error, false);
    } else {
      setStep((prev) => prev + 1);
      popupNotification(response.message, true);
    }
    setShowModal(false);
    setTextFieldValue('');
  };
  return (
    <>
      <Box mb={3}>
        <Stepper activeStep={step}>
          {steps.map((label, index) => (
            <Step key={index}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
      {step == 0 && (
        <>
          <CustomCard>
            <Typography variant="subtitle2" color="textDisabled">
              Do you want to enroll in contract management with e-signature ?
            </Typography>
            <Box display="flex" gap={4} mt={5}>
              <Button
                variant="contained"
                size="small"
                onClick={() => setShowModal(true)}
              >
                Yes
              </Button>
              <Button
                variant="outlined"
                size="small"
                onClick={() => navigateWithUuid('dashboard')}
              >
                No
              </Button>
            </Box>
            <Box display="flex" mt={2} gap={1} alignItems="center">
              <Typography variant="subtitle2" component="span">
                Note:{' '}
              </Typography>
              <Typography
                variant="subtitle2"
                color="textPrimary"
                component="span"
              >
                Service available in USA <img src={USAFlag} height={20} /> and
                India <img src={IndiaFlag} height={20} /> at this time
              </Typography>
            </Box>
          </CustomCard>
          <CustomModal
            type="info"
            maxWidth="lg"
            title="Enroll for contract management with e-signature"
            primaryButtonLabel="Confirm"
            primaryButtonDisabled={textFieldValue != 'I agree'}
            primaryButtonAction={saveConsent}
            open={showModal}
            autoClose={false}
            onClose={() => setShowModal(false)}
            secondaryButtonLabel="Cancel"
            secondaryButtonAction={() => {}}
            primaryButtonLoading={loading}
          >
            <Box>
              <SampleContract />
              <form onSubmit={saveConsent}>
                <Box display="flex" gap={4} mt={2} alignItems="center">
                  <Typography variant="body2" component="span">
                    Please type{' '}
                    <Typography
                      fontWeight="bold"
                      variant="body2"
                      component="span"
                    >
                      "I agree"
                    </Typography>{' '}
                    to agree with terms and conditions
                  </Typography>

                  <TextField
                    value={textFieldValue}
                    onChange={(
                      e: React.ChangeEvent<
                        HTMLInputElement | HTMLTextAreaElement
                      >
                    ) => setTextFieldValue(e.target.value)}
                    sx={{ width: '45%' }}
                    placeholder="Type I agree to agree with terms and conditions"
                    size="small"
                  />
                </Box>
              </form>
            </Box>
          </CustomModal>
        </>
      )}

      {step == 1 && (
        <>
          <CustomCard>
            <Box display="flex" justifyContent="space-between">
              <Typography variant="body2" fontWeight="bold">
                Please select your country and proceed.
              </Typography>
            </Box>
            <Box display="flex" gap={5} mt={5}>
              {
                <Box display="flex" flexDirection="column">
                  <IconButton
                    disableRipple
                    onClick={() => setSelectedCountry('IN')}
                  >
                    <img src={IndiaFlag} alt="" height={100} />
                  </IconButton>

                  <Box textAlign="center">
                    <Radio
                      value="IN"
                      onChange={(e) => setSelectedCountry(e.target.value)}
                      checked={selectedCountry == 'IN'}
                      icon={<CheckBoxOutlineBlankIcon />} // Icon when unchecked
                      checkedIcon={<CheckBoxIcon />} // Icon when checked
                    />
                  </Box>
                </Box>
              }
              {
                <Box display="flex" flexDirection="column">
                  <IconButton disableRipple>
                    <img src={USAFlag} alt="" height={100} />
                  </IconButton>
                  <Box textAlign="center">
                    <Radio
                      value="USA"
                      onChange={(e) => setSelectedCountry(e.target.value)}
                      checked={selectedCountry == 'USA'}
                      icon={<CheckBoxOutlineBlankIcon />} // Icon when unchecked
                      checkedIcon={<CheckBoxIcon />} // Icon when checked
                    />
                  </Box>
                </Box>
              }
            </Box>
            <Box display="flex" justifyContent="flex-end">
              <GradientButton
                disabled={selectedCountry == ''}
                onClick={() => setStep((prev) => prev + 1)}
              >
                Continue
              </GradientButton>
            </Box>
          </CustomCard>
        </>
      )}

      {step == 2 && (
        <>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="body2" fontWeight="bold">
              Please deposit{' '}
              <Typography
                component="span"
                fontWeight="bold"
                variant="h5"
                className="gradientText"
              >
                {' '}
                {`${selectedCountry == 'USA' ? '$20' : '2000 ₹'}`}{' '}
              </Typography>{' '}
              in your account to continue creating contracts.
            </Typography>
            <GradientButton onClick={() => setStep((prev) => prev + 1)}>
              Next
            </GradientButton>
          </Box>
          <Typography variant="body1" fontWeight="bold">
            Note:{' '}
            <Typography component="span" variant="body1">
              In payment description please enter
            </Typography>{' '}
            <Typography
              component="span"
              variant="body1"
              className="gradientText"
            >
              {username}
            </Typography>
          </Typography>
          <Box
            display="flex"
            justifyContent="center"
            flexDirection="column"
            alignItems="center"
          >
            <Box display="flex" alignItems="center">
              <img src={logo} alt="Tasalli Corporation" height={100} />
              <Typography variant="body2" fontWeight="bold">
                Tasalli Corporation
              </Typography>
            </Box>
            <CustomCard>
              <img src={QR} height={400} width={330} />
            </CustomCard>
          </Box>
        </>
      )}
      {step == 3 && (
        <Box
          textAlign="center"
          display="flex"
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
        >
          <Lottie
            animationData={lottieAnimation}
            loop={true}
            style={{ height: 300, width: 300 }}
          />
          <Typography variant="h6" color={green[400]}>
            Thank you for making the payment.
          </Typography>
          <Typography variant="h6" color={green[400]}>
            Your account will be credited within 24-hours.
          </Typography>
          <Typography variant="h6" color={green[400]}>
            Please check back to start creating contracts.
          </Typography>
        </Box>
      )}
    </>
  );
};

export default SetupContractManagement;
