import { useState } from 'react';

const useInvoiceTab = () => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  return { activeTab, setActiveTab, handleTabChange };
};

export default useInvoiceTab;
