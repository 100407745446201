import { useEffect, useState } from 'react';

import { Box, Grid, Typography } from '@mui/material';
import { APIEndpoint } from 'common/enum';
import { staffGroupInterface } from 'common/interface';
import { universalPostRequest } from 'common/requestHandler';
import { getUsers } from 'common/utils';
import GradientButton from 'components/elements/GradientButton';
import HeaderComp from 'components/elements/HeadingComponent';
import OverlayLoader from 'components/elements/OverlayLoader';
import SelectableList from 'components/ListItemsWithSearch';
import useSelectableItems from 'hooks/useSelectableList';
import { popupNotification } from 'notifications/ToastNotification';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';

import { getStaffGroups, getUserInterface } from './utils';

const AddStaffToGroup = () => {
  const { companyId, employeeId } = useSelector(
    (state: RootState) => state.auth
  );
  const [staffGroups, setStaffGroups] = useState([] as staffGroupInterface[]);
  const [selectedStaffGroup, setSelectedStaffGroup] = useState(
    {} as staffGroupInterface
  );
  const [loading, setLoading] = useState(true);
  const [creatingGroup, setCreatingGroup] = useState(false);
  const [usersList, setUsersList] = useState<getUserInterface[]>([]);
  //   const [leftItems, setLeftItems] = useState<getUserInterface[]>(usersList);
  const { selectedItems, toggleItem, resetSelection } = useSelectableItems();
  const {
    selectedItems: selectedLeftItems,
    toggleItem: toggleLeftItem,
    resetSelection: resetLeftSelection,
  } = useSelectableItems();
  const getUsersList = async () => {
    setLoading(true);
    const response = await getUsers(companyId, 'GetStaff', employeeId);
    if ('error' in response) {
      popupNotification('', false);
    } else {
      setUsersList(response.data);
    }
    setLoading(false);
  };
  useEffect(() => {
    const filteredGroup = staffGroups.filter(
      (group) => group.companyStaffGroupName == selectedItems[0]
    );
    setSelectedStaffGroup(filteredGroup[0]);
    // setStaffGroupMembers(filteredGroup.flatMap((group) => group.employeeIds));
  }, [selectedItems]);
  const addStaffToGroup = async () => {
    setCreatingGroup(true);
    const payload = {
      companyStaffGroupId: selectedStaffGroup.companyStaffGroupId,
      companyStaffGroupName: selectedStaffGroup.companyStaffGroupName,
      actionName: 'AddAStaff',
      employeeId: selectedLeftItems,
    };
    const response = await universalPostRequest(
      APIEndpoint.createStaffGroup,
      payload
    );
    if ('error' in response) {
      popupNotification(response.error, false);
    } else {
      popupNotification(response.message, true);
      setSelectedStaffGroup({} as staffGroupInterface);
      resetLeftSelection();
      resetSelection();
    }
    setCreatingGroup(false);
  };
  useEffect(() => {
    getUsersList();
    getStaffGroups(companyId, setStaffGroups, setLoading);
  }, []);
  return (
    <>
      <HeaderComp>Add staff to group</HeaderComp>
      <OverlayLoader loading={creatingGroup} text="Please wait" />
      <Grid container spacing={4}>
        <Grid item lg={6}>
          <Typography variant="body1" mb={1} color="textDisabled">
            Staff users
          </Typography>
          <SelectableList
            items={usersList}
            selectedItems={selectedLeftItems}
            toggleItem={toggleLeftItem}
            searchVisible={true}
            labelKey="name"
            idKey="id"
            loading={loading}
          />
        </Grid>
        <Grid item lg={6}>
          <Typography variant="body1" mb={1} color="textDisabled">
            Staff groups
          </Typography>
          <Box
            height="100%"
            sx={{
              borderRadius: 4,
              boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
            }}
          >
            <SelectableList
              items={staffGroups}
              selectedItems={selectedItems}
              toggleItem={toggleItem}
              searchVisible={true}
              showSelectHighlight
              showCheckBoxIcon={false}
              allowSingleSelection
              loading={loading}
              idKey="companyStaffGroupName" // Nested key
              labelKey="companyStaffGroupName" // Nested label
              height={150}
            />
          </Box>
        </Grid>
        <Grid lg={12}>
          <Box display="flex" justifyContent="flex-end" mt={8}>
            <GradientButton
              variant="contained"
              rounded
              color="error"
              disabled={selectedItems.length === 0}
              onClick={addStaffToGroup}
            >
              Add staff
            </GradientButton>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default AddStaffToGroup;
