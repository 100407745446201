import { useEffect } from 'react';

import { APIEndpoint } from 'common/enum';
import { universalPostRequest } from 'common/requestHandler';
import HeaderComp from 'components/elements/HeadingComponent';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';

import NewContract from './newContract/NewContract';
import SetupContractManagement from './signup/SetupContractManagement';

const ContractManagement = () => {
  const { isSignVerifyEnabled, companyId, customerId, employeeId, username } =
    useSelector((state: RootState) => state.auth);

  const getContracts = async () => {
    const payload = {
      companyId,
      username,
      httpMethod: 'GET',
      actionName: 'GetContracts',
    };
    const response = await universalPostRequest(
      APIEndpoint.SignUpForDocSign,
      payload
    );
  };
  useEffect(() => {
    getContracts();
  }, []);
  return (
    <>
      <HeaderComp>Contracts</HeaderComp>
      {!isSignVerifyEnabled && <SetupContractManagement />}
      <NewContract />
      {/* <ESign /> */}
    </>
  );
};

export default ContractManagement;
