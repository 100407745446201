import React, { useEffect, useRef, useState } from 'react';

import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';
import { Box, Button, Typography } from '@mui/material';
import CustomIconButton from 'components/elements/IconButton';
import StepProgress from 'components/ProgressBar';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import PDFViewerSign from '../ESign';
import { parseContractData } from '../utils';
import StepOne from './StepOne';
import StepThree from './StepThree';
import DynamicForm from './StepTwo';

interface createContractInterface {
  levels: number;
}

const NewContract = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [contractDetails, setContractDetails] = useState({} as any);
  const [pdfFile, setPdfFile] = useState<File | null>(null);
  const formRefs = useRef<any[]>([]);
  useEffect(() => {
    if (contractDetails.levels) {
      formRefs.current = Array.from(
        { length: contractDetails.levels },
        (_, i) => formRefs.current[i] || React.createRef()
      );
    }
  }, [contractDetails.levels]);

  const handleSubmitAll = async () => {
    const results = await Promise.all(
      formRefs.current.map((ref) => ref.current?.validateAndGetValues())
    );

    const validForms = results.filter((res) => res !== null);

    if (validForms.length === formRefs.current.length) {
      console.log('All forms are valid:', validForms);
      const data = parseContractData(validForms);
      console.log(data);
      setContractDetails((prev: any) => ({
        ...prev,
        dynamicForms: validForms,
      }));
    } else {
      console.log('Some forms have errors. Please fix them.');
    }
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <Box display="flex" justifyContent="center">
        <Box
          border="1px solid rgb(209, 211, 215)"
          p={4}
          borderRadius={4}
          width="70%"
        >
          <StepProgress
            steps={3}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
          />
          <Box mt={5}>
            {currentStep === 0 && (
              <StepOne
                setContractDetails={setContractDetails}
                contractDetails={contractDetails}
              />
            )}
            {currentStep === 1 && (
              <>
                {/* <DndProvider backend={HTML5Backend}> */}
                {formRefs.current.map((ref, index) => (
                  <>
                    <Typography variant="h6" fontWeight="bold" mb={2}>
                      Level {index + 1}
                    </Typography>
                    <DynamicForm
                      key={index}
                      ref={ref}
                      setContractDetails={setContractDetails}
                    />
                  </>
                ))}
                {/* </DndProvider> */}
                <Box display="flex" justifyContent="center" mb={4}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmitAll}
                  >
                    Submit All
                  </Button>
                </Box>
              </>
            )}
            {currentStep === 2 && <StepThree setPdfFile={setPdfFile} />}
          </Box>
          <Box display="flex" justifyContent="flex-end" gap={5}>
            <CustomIconButton
              buttonType="primary"
              tooltipTitle="Back"
              onClick={() => {
                currentStep > 0 && setCurrentStep((prev) => prev - 1);
              }}
            >
              <FirstPageIcon color="primary" />
            </CustomIconButton>
            <CustomIconButton
              buttonType="primary"
              tooltipTitle="Next"
              onClick={() => {
                currentStep <= 2 && setCurrentStep((prev) => prev + 1);
              }}
            >
              <LastPageIcon color="primary" />
            </CustomIconButton>
          </Box>
        </Box>
      </Box>
      <PDFViewerSign pdfFile={pdfFile} setPdfFile={setPdfFile} />
    </DndProvider>
  );
};

export default NewContract;
