import { useEffect, useRef, useState } from 'react';

import { Save } from '@mui/icons-material';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import {
  Box,
  Divider,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { APIEndpoint } from 'common/enum';
import { universalPostRequest } from 'common/requestHandler';
import CustomModal from 'components/CustomModal';
import CustomIconButton from 'components/elements/IconButton';
import OverlayLoader from 'components/elements/OverlayLoader';
import dayjs from 'dayjs';
import { popupNotification } from 'notifications/ToastNotification';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';

import {
  CompanyDataInterface,
  InvoiceProductRow,
  savedInvoiceInterface,
} from '../types';
import {
  calculateDiscountAmount,
  formatAmount,
  getCompanyDetailsForInvoiceCreation,
  saveComponentAsPDF,
} from '../utils';
import CompanyDetailsComp from './createInvoiceComp/CompanyDetailsComp';
import CustomerDetailsComp from './createInvoiceComp/CustomerDetailsComp';
import PaymentDetailsComp from './createInvoiceComp/PaymentDetailsComp';
import SmallDateComp from './DateComp';
import Footer from './Footer';
import ProductTable from './Producttable';
const header = 'Invoice';
interface AccountDetailsInterface {
  accountName: string;
  bankName: string;
  accountNumber: string;
  accountType: string;
  codeType: string;
  codeName: string;
}

const InvoicePage = () => {
  const [invoiceDetails, setInvoiceDetails] = useState<savedInvoiceInterface>({
    address: '',
    paymentTerms: '',
    invoiceNo: dayjs().format('MMDDHHmmss'),
    invoiceDate: dayjs(),
    dueDate: null,
    flatDiscount: 0,
    amountAfterDiscount: 0,
    notes: '',
  } as savedInvoiceInterface);
  const [data, setData] = useState({} as CompanyDataInterface);
  const [rows, setRows] = useState<InvoiceProductRow[]>([]);
  const [isSaved, setIsSaved] = useState(false);
  const [editCompanyAddress, setEditCompanyAddress] = useState(true);
  const invoiceRef = useRef<HTMLDivElement | null>(null);
  const { companyId } = useSelector((state: RootState) => state.auth);
  const [isSavingInvoice, setIsSavingInvoice] = useState(false);
  const [isFetching, setIsFetching] = useState(true);
  const [showResetModal, setShowResetModal] = useState(false);
  const handleTotalsUpdate = (
    subTotal: number,
    totalTax: number,
    totalAmount: number,
    amountAfterDiscount: number
  ) => {
    setInvoiceDetails((prev) => ({
      ...prev,
      subTotal,
      totalAmount,
      totalTax,
      amountAfterDiscount,
    }));
  };

  useEffect(() => {
    if (!invoiceDetails.paymentTerms) return;
    if (invoiceDetails.paymentTerms === 'Immediate') {
      let dueDate = dayjs().add(3, 'days');
      setInvoiceDetails((prev) => ({
        ...prev,
        dueDate: dueDate,
      }));
      return;
    }
    const daysToAdd = parseInt(invoiceDetails.paymentTerms.split(' ')[1], 10);
    if (isNaN(daysToAdd)) return;
    const newDueDate = dayjs().add(daysToAdd, 'days');
    setInvoiceDetails((prev) => ({
      ...prev,
      dueDate: newDueDate,
    }));
  }, [invoiceDetails.paymentTerms]);

  useEffect(() => {
    const discAmount = calculateDiscountAmount(
      invoiceDetails.discountPercentage,
      invoiceDetails.subTotal
    );
    setInvoiceDetails((prev) => ({ ...prev, discountAmount: discAmount || 0 }));
  }, [invoiceDetails.discountPercentage, invoiceDetails.subTotal]);
  useEffect(() => {
    getCompanyDetailsForInvoiceCreation(companyId, setData, setIsFetching);
  }, []);

  const saveInvoice = async () => {
    setIsSaved(true);
    setIsSavingInvoice(true);
    const payload = {
      httpMethod: 'POST',
      actionName: 'CreateCustomerInvoice',
      customerId: invoiceDetails?.customer?.customerId,
      // customerId: '3b21757e-45a2-464f-ba67-9079e39d3b49',
      invoiceDetails: {
        ...invoiceDetails,
        dueDate: invoiceDetails.dueDate?.format('YYYY-MM-DD'),
        invoiceDate: invoiceDetails.invoiceDate?.format('YYYY-MM-DD'),
        products: rows,
      },
    };
    const response = await universalPostRequest(
      APIEndpoint.secDocsMain,
      payload
    );
    setIsSavingInvoice(false);
    if ('error' in response) {
      popupNotification(response.error, false);
    } else {
      popupNotification(response.message, true);
    }
    reset();
  };
  const isSaveButtonActive = () => {
    return !invoiceDetails.customer || isSaved;
  };
  const reset = () => {
    setInvoiceDetails({
      address: '',
      paymentTerms: '',
      invoiceNo: dayjs().format('YYMMDDHHmmss'),
      invoiceDate: dayjs(),
      dueDate: null,
      flatDiscount: 0,
      amountAfterDiscount: 0,
      notes: '',
    } as savedInvoiceInterface);
    setRows([] as InvoiceProductRow[]);
    setIsSaved(false);
    setEditCompanyAddress(true);
  };
  const handleNewInvoiceClick = () => {
    if (invoiceDetails?.totalAmount > 0) {
      setShowResetModal(true);
    } else {
      reset();
    }
  };

  return (
    <>
      <Box textAlign="right" mr={5}>
        {/* <IconButton onClick={saveInvoice} disabled={true}>
          <Save color="primary" />
        </IconButton> */}
        <OverlayLoader
          loading={isFetching || isSavingInvoice}
          text={isFetching ? 'Please wait...' : 'Saving invoice...'}
        />
        <CustomIconButton
          tooltipTitle="Create new invoice"
          buttonType="primary"
          onClick={handleNewInvoiceClick}
        >
          <NoteAddIcon />
        </CustomIconButton>
        <CustomIconButton
          tooltipTitle="Save"
          buttonType="primary"
          disabled={isSaveButtonActive()}
          onClick={saveInvoice}
        >
          <Save />
        </CustomIconButton>
        {/* <Button onClick={() => setIsSaved(true)}>Save as pdf</Button> */}
        {/* <IconButton onClick={() => saveComponentAsPDF(invoiceRef)}>
          <SimCardDownloadIcon color="success" />
        </IconButton> */}
        <CustomIconButton
          tooltipTitle="Download"
          buttonType="success"
          disabled={!isSaved}
          onClick={() => saveComponentAsPDF(invoiceRef)}
        >
          <SimCardDownloadIcon />
        </CustomIconButton>
      </Box>
      {/* <Button onClick={() => saveComponentAsPDF(invoiceRef)}>
        Save as pdf2
      </Button> */}
      <Box ref={invoiceRef} p={2} height="100%">
        <Grid
          container
          p={2}
          border="1px solid lightgrey"
          borderRadius={8}
          height="100%"
        >
          <Grid item xs={12} pl={2}>
            <Typography variant="h6" fontWeight="bold">
              {header}
            </Typography>
          </Grid>
          <Grid item md={3} pl={2}>
            <CompanyDetailsComp
              {...{
                invoiceDetails,
                setInvoiceDetails,
                isSaved,
                setEditCompanyAddress,
                editCompanyAddress,
                companyData: data,
              }}
            />
          </Grid>
          <Grid
            container
            item
            xs={12}
            borderRadius={4}
            bgcolor={grey[100]}
            pb={4}
            pt={4}
          >
            <Grid container item xs={12} pl={2} spacing={2}>
              <Grid item xs={4}>
                <CustomerDetailsComp
                  {...{ invoiceDetails, setInvoiceDetails, isSaved }}
                />
              </Grid>
              <Grid container item xs={12}>
                <Grid item xs={5}>
                  <Box
                    border="1px solid #ccc"
                    overflow="auto"
                    padding="8px"
                    minHeight="70px"
                    bgcolor="#f9f9f9"
                    borderRadius="8px"
                  >
                    {invoiceDetails.customer?.address
                      .split(',')
                      .map((addressBits) => (
                        <Typography variant="body2">{addressBits}</Typography>
                      )) || ''}
                  </Box>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid container item xs={6} alignItems="center">
                  <Grid item xs={3}>
                    <Typography variant="body2">Invoice no.</Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography variant="body2">
                      {invoiceDetails.invoiceNo}
                    </Typography>
                  </Grid>

                  <Grid item xs={4}></Grid>
                  <Grid item xs={3}>
                    <Typography variant="body2">P.O. number</Typography>
                  </Grid>
                  <Grid item xs={5}>
                    {isSaved ? (
                      <Typography>{invoiceDetails.poNumber}</Typography>
                    ) : (
                      <TextField
                        fullWidth
                        value={invoiceDetails.poNumber}
                        onChange={(e) =>
                          setInvoiceDetails((prev) => ({
                            ...prev,
                            poNumber: e.target.value,
                          }))
                        }
                        size="small"
                        variant="outlined"
                      />
                    )}
                  </Grid>
                  <Grid item xs={4}></Grid>
                  <Grid item xs={3}>
                    <Typography variant="body2">Invoice date</Typography>
                  </Grid>
                  <Grid item xs={5}>
                    {isSaved ? (
                      <Typography variant="body2">
                        {invoiceDetails.invoiceDate
                          ?.toDate()
                          .toLocaleDateString()}
                      </Typography>
                    ) : (
                      <SmallDateComp
                        value={invoiceDetails.invoiceDate}
                        onChange={(newValue) =>
                          setInvoiceDetails((prev) => ({
                            ...prev,
                            invoiceDate: dayjs(newValue),
                          }))
                        }
                      />
                    )}
                  </Grid>
                  <Grid item xs={4}></Grid>
                  <Grid item xs={3}>
                    <Typography variant="body2">Payment terms</Typography>
                  </Grid>
                  <Grid item xs={5}>
                    {isSaved ? (
                      <Typography variant="body2">
                        {invoiceDetails?.paymentTerms}
                      </Typography>
                    ) : (
                      <Select
                        value={invoiceDetails?.paymentTerms || ''}
                        onChange={(e: SelectChangeEvent<string>) =>
                          setInvoiceDetails((prev) => ({
                            ...prev,
                            paymentTerms: e.target.value,
                          }))
                        }
                        displayEmpty
                      >
                        <MenuItem value="" disabled>
                          Select payment terms
                        </MenuItem>
                        {data?.paymentTerms?.map((term) => (
                          <MenuItem key={term} value={term}>
                            {term}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  </Grid>
                  <Grid item xs={4}></Grid>
                  <Grid item xs={3}>
                    <Typography variant="body2">Due date</Typography>
                  </Grid>
                  <Grid item xs={5}>
                    {isSaved ? (
                      <Typography variant="body2">
                        {invoiceDetails.dueDate?.toDate().toLocaleDateString()}
                      </Typography>
                    ) : (
                      <SmallDateComp
                        value={invoiceDetails.dueDate}
                        onChange={(newValue) =>
                          setInvoiceDetails((prev) => ({
                            ...prev,
                            dueDate: dayjs(newValue),
                          }))
                        }
                      />
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <ProductTable
              onTotalsUpdate={handleTotalsUpdate}
              discountAmount={invoiceDetails.discountAmount}
              isSaved={isSaved}
              setRows={setRows}
              rows={rows}
              discountPercentage={invoiceDetails.discountPercentage || 0}
              flatDiscount={invoiceDetails.flatDiscount}
            />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid container item xs={12} mt={5} spacing={4}>
            <Grid container spacing={4} item xs={9}>
              {/* !!Payment QR code component */}
              <Grid item xs={6}>
                <PaymentDetailsComp
                  invoiceDetails={invoiceDetails}
                  setInvoiceDetails={setInvoiceDetails}
                  isSaved={isSaved}
                  companyData={data}
                  companyId={companyId}
                />
              </Grid>
              <Grid item xs={6}></Grid>
              <Grid item xs={6}>
                <Typography variant="body2" fontWeight="bold">
                  Note to customer
                </Typography>
              </Grid>
              <Grid item xs={6}></Grid>
              <Grid item xs={6}>
                <TextField
                  multiline
                  rows={3}
                  value={invoiceDetails.notes}
                  variant="outlined"
                  placeholder="Tell your customer how you want to get paid"
                  fullWidth
                  onChange={(e) =>
                    setInvoiceDetails((prev) => ({
                      ...prev,
                      notes: e.target.value,
                    }))
                  }
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '10px',
                      backgroundColor: '#f9f9f9',
                      '&:hover fieldset': {
                        borderColor: '#3bd6c6',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#3bd6c6',
                        borderWidth: '2px',
                      },
                    },
                    '& .MuiInputLabel-root': {
                      color: '#666',
                    },
                    '& .MuiInputLabel-root.Mui-focused': {
                      color: '#3bd6c6',
                    },
                  }}
                />
              </Grid>
            </Grid>
            <Grid container item xs={3} spacing={0}>
              <Grid item xs={8}>
                <Typography variant="body1">Sub total</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography align="right" variant="body1">
                  {formatAmount(invoiceDetails?.subTotal)}
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body1">Discount(%)</Typography>
              </Grid>
              <Grid item xs={4}>
                {isSaved ? (
                  <Typography variant="body1" align="right">
                    {invoiceDetails.discountPercentage}%
                  </Typography>
                ) : (
                  <TextField
                    type="number"
                    size="small"
                    inputProps={{
                      inputMode: 'numeric',
                      pattern: '[0-9]*',
                    }}
                    onChange={(
                      e: React.ChangeEvent<
                        HTMLInputElement | HTMLTextAreaElement
                      >
                    ) =>
                      setInvoiceDetails((prev) => ({
                        ...prev,
                        discountPercentage: Number(e.target.value),
                      }))
                    }
                  />
                )}
              </Grid>

              <Grid item xs={8}>
                <Typography variant="body1">Amount after discount</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography align="right" variant="body1">
                  {formatAmount(invoiceDetails?.amountAfterDiscount)}
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body1">Total tax</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography align="right" variant="body1">
                  {formatAmount(invoiceDetails?.totalTax)}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body1" fontWeight="bold">
                  Invoice total
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography align="right" variant="body1" fontWeight="bold">
                  {formatAmount(invoiceDetails?.totalAmount)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} mt={4}>
            <Typography variant="body2" fontWeight="bold">
              Terms and conditions
            </Typography>
            <Typography variant="subtitle2" fontWeight="thin">
              {data?.termsAndConditions}
            </Typography>
          </Grid>
          <Grid item xs={12} mt={4}>
            <Divider />
          </Grid>
          <Grid item xs={12} textAlign="center" mt={4}>
            <Footer />
          </Grid>
        </Grid>
      </Box>
      <CustomModal
        type="warning"
        title="Unsaved invoice"
        open={showResetModal}
        onClose={() => setShowResetModal(false)}
        primaryButtonLabel="Yes"
        primaryButtonAction={() => saveInvoice()}
        secondaryButtonAction={() => setShowResetModal(false)}
        secondaryButtonLabel="No"
      >
        <Typography variant="body1" mt={1}>
          The Invoice details are not saved. Would you like to Save now?
        </Typography>
      </CustomModal>
    </>
  );
};

export default InvoicePage;
