const colors = {
  primary: '#1270dc', // Blue color for primary
  secondary: '#f50057', // Pink color for secondary
  warning: 'rgb(255, 152, 0)',
  gradientColor1: '#42d392',
  gradientColor2: '#647eff',
  errorGradientColor1: '#e23d67',
  errorGradientColor2: '#ff0054',
  sidebarHoverColor: '#D0E8FF',
  editIcon: '#66b2b2',
  highlight: '#5abfeb',
  errorMain: '#ff6347',
  // Add more colors if needed
};

export default colors;
