import React, { useEffect, useState } from 'react';

import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import {
  AppBar,
  Box,
  Button,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from '@mui/material';
import { APIEndpoint } from 'common/enum';
import { universalGetRequest } from 'common/requestHandler';
import { logoutUser } from 'common/utils';
import { userTypes } from 'config/config';
import { popupNotification } from 'notifications/ToastNotification';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';

import useNavigateWithUUID from '../hooks/useNavigateWithUUID';
import { logout } from '../redux/slices/authSlice';
const Navigation: React.FC = () => {
  const dispatch = useDispatch();
  const { navigateWithUuid } = useNavigateWithUUID();
  const [invoiceDue, setInvoiceDue] = useState(0 as number);
  const [logoURL, setLogoURL] = useState<string>('');
  const { firstName, companyId, role } = useSelector(
    (state: RootState) => state.auth
  );
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const getLogoURL = async () => {
    const response: any = await universalGetRequest(APIEndpoint.getLogo, {
      folderKey: `${companyId}/media/logo.png`,
    });
    if ('error' in response) {
      popupNotification(response.error, false);
    } else {
      setLogoURL(response.data);
    }
  };
  const getInvoiceDueAmount = async () => {
    const response: any = await universalGetRequest(
      APIEndpoint.getSecDocsMain,
      {
        httpMethod: 'GET',
        actionName: 'DashboardInvoiceDue',
      }
    );
    if ('error' in response) {
    } else {
      if (response.data.amountDue) {
        setInvoiceDue(response.data.amountDue);
      }
    }
  };
  useEffect(() => {
    getLogoURL();
    getInvoiceDueAmount();
  }, []);

  const open = Boolean(anchorEl);
  return (
    <AppBar
      position="fixed"
      color="transparent"
      sx={{ backgroundColor: '#f8f9fa' }}
      elevation={0}
    >
      <Toolbar>
        {/* Logo */}
        <Box display="flex" flex={1}>
          <img src={logoURL} height={50} />
        </Box>

        {/* Navigation Links */}
        <Box display="flex" alignItems="center">
          {role === userTypes.corporateAdmin ||
          (role === userTypes.individual && invoiceDue > 0) ? (
            <Typography
              variant="subtitle2"
              component={Button}
              onClick={() => navigateWithUuid('invoice-management')}
              color="error"
            >
              Payment due: {invoiceDue ? invoiceDue.toFixed(2) : null}
            </Typography>
          ) : null}
          <Typography
            variant="subtitle2"
            component="div"
            ml={2}
            mr={2}
            color="textDisabled"
          >
            Hi, {'  '}
            <Typography component="span">
              {firstName &&
                firstName?.charAt(0).toUpperCase() +
                  firstName?.slice(1).toLowerCase()}
            </Typography>
          </Typography>
          <IconButton onClick={handleClick} size="small" color="inherit">
            <PersonIcon fontSize="medium" color="primary" />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            PaperProps={{
              sx: {
                borderRadius: 2, // Set the border radius here (adjust the value as needed)
                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', // Optional: Add a soft shadow for a smoother appearance
              },
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            {/* Page Menu Item */}

            {/* Preferences menu item */}
            <MenuItem
              onClick={() => {
                navigateWithUuid('preference');
                handleClose();
              }}
            >
              <ListItemIcon>
                <InsertDriveFileIcon fontSize="small" color="primary" />
              </ListItemIcon>
              <Typography variant="subtitle2" color="textDisabled">
                Preferences
              </Typography>
            </MenuItem>

            {/* Logout Menu Item */}
            <MenuItem
              onClick={() => {
                logoutUser();
                dispatch(logout());
              }}
            >
              <ListItemIcon>
                <LogoutIcon fontSize="small" color="error" />
              </ListItemIcon>
              <Typography variant="subtitle2" color="textDisabled">
                Logout
              </Typography>
            </MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Navigation;
