import { Box, TextField, Typography } from '@mui/material';

interface Props {
  setContractDetails: React.Dispatch<any>;
  contractDetails: any;
}
const StepOne: React.FC<Props> = ({ setContractDetails, contractDetails }) => {
  return (
    <Box>
      <Box display="flex" gap={4} alignItems="center">
        <Typography variant="body2" mt={2}>
          How many levels of signature are expected?
        </Typography>
        <TextField
          label=""
          value={contractDetails.levels}
          type="number"
          sx={{ mt: 2 }}
          autoFocus
          onChange={(e) =>
            setContractDetails((prev: any) => ({
              ...prev,
              levels: e.target.value,
            }))
          }
        />
      </Box>

      <Box mt={2} display="flex" gap={4} alignItems="center">
        <Typography variant="body2" mt={2}>
          How long (Years) would you like to retain the document?
        </Typography>
        <TextField
          label=""
          value={contractDetails.levels}
          type="number"
          sx={{ mt: 2 }}
          autoFocus
          onChange={(e) =>
            setContractDetails((prev: any) => ({
              ...prev,
              levels: e.target.value,
            }))
          }
        />
      </Box>
      <Box mt={2} display="flex" alignItems="center" gap={4}>
        <Typography variant="body2">Your role</Typography>
        <TextField
          label=""
          value={contractDetails.levels}
          type="number"
          sx={{ mt: 2 }}
          autoFocus
          onChange={(e) =>
            setContractDetails((prev: any) => ({
              ...prev,
              levels: e.target.value,
            }))
          }
        />
      </Box>
    </Box>
  );
};

export default StepOne;
